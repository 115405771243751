import {AxiosRequestConfig} from 'axios'
import axiosConfig from '../../../api/axiosConfig'

const URL = '/batches'
const URL_ORDERS = '/orders'
const URL_TICKETS = '/tickets'

const getList = (config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.get(URL, config)
}

const getDetail = (id: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.get(`${URL}/${id}`, config)
}

const getA1BatchOrders = (config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.get(`${URL_ORDERS}`, config)
}

const cancelA1Batch = (id: any, payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL}/${id}/cancel`, payload, config)
}

const confirmPickUpDate = (id: any, payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.patch(`${URL}/${id}/confirm-pickup-date`, payload, config)
}

const markAsPickedUp = (id: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL}/${id}/mask-as-picked-up`, {}, config)
}

const updateJourneyInfo = (id: any, payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL}/${id}/update-journey-info`, payload, config)
}

const downloadOrdersA1Batch = (id: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.get(`${URL}/${id}/download-template`, config)
}

const store = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL}`, payload, config)
}

const uploadCSV = (id: any, payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL}/${id}/upload-file`, payload, config)
}

const notificationDestination = (id: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL}/${id}/notification`, {}, config)
}

const markAsShipped = (id: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL}/${id}/mask-as-shipped`, {}, config)
}

const printLabel = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL}/print`, payload, config)
}

const updateA1Batch = (id: any, payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.put(`${URL}/${id}`, payload, config)
}

const removeBatch = (id: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.delete(`${URL}/${id}`, config)
}

// BEGIN: Ticket Forum
const createTicket = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL_TICKETS}`, payload, config)
}

const getTickets = (id: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.get(`${URL}/${id}/tickets`, config)
}

const getTicketDetail = (id: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.get(`${URL_TICKETS}/${id}`, config)
}

const updateTicket = (id: any, payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.put(`${URL_TICKETS}/${id}`, payload, config)
}

const deleteTicket = (id: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.delete(`${URL_TICKETS}/${id}`, config)
}

const postComments = (
  id: number | string,
  payload: any,
  config?: AxiosRequestConfig | undefined
) => {
  return axiosConfig.post(`${URL_TICKETS}/${id}/comments`, payload, config)
}

// END: Ticket Forum

// BEGIN: Filter order
const getDataSaveFilter = (config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.get('/filters', config)
}

const createDataSaveFilter = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post('/filters', payload, config)
}

const deleteDataSaveFilter = (id: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.delete(`/filters/${id}`, config)
}
// END: Filter order

// ORDER
const URL_ORDER = '/orders'

const orderPrint = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL_ORDER}/print`, payload, config)
}

const releasedOrder = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL_ORDER}/released`, payload, config)
}

// SHIPMENT
const URL_SHIPMENT = '/shipments'

const shipmentStore = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(URL_SHIPMENT, payload, config)
}

const shipmentRefund = (id: any, payload?: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL_SHIPMENT}/refund/${id}`, payload, config)
}

const shipmentPrint = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL_SHIPMENT}/print`, payload, config)
}

const shipmentPrintPackingSlip = (payload?: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL_SHIPMENT}/packing-slips/print`, payload, config)
}

const shipmentPrintReturnLabel = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL_SHIPMENT}/returns`, payload, config)
}

const shipmentStorePackingSlip = (payload?: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL_SHIPMENT}/packing-slips`, payload, config)
}

const setDropOff = (id?: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL}/${id}/drop-off`, config)
}

const markAsDroppedOff = (id?: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL}/${id}/mask-as-dropped-off`, config)
}

//DOCUMENTS
const DOCUMENTS = 'documents'

const t86Documents = (id?: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.get(`${URL}/${id}/${DOCUMENTS}`, config)
}

const uploadT86Documents = (id?: any, payload?: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL}/${id}/${DOCUMENTS}`, payload, config)
}

const deleteT86Documents = (id?: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.delete(`${DOCUMENTS}/${id}/delete`, config)
}

const checkExistsDocument = (id?: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.get(`${URL}/${id}/${DOCUMENTS}/exists`, config)
}

export const A1BatchService = {
  getList,
  getDetail,
  getA1BatchOrders,
  cancelA1Batch,
  confirmPickUpDate,
  markAsPickedUp,
  updateJourneyInfo,
  markAsShipped,
  downloadOrdersA1Batch,
  store,
  uploadCSV,
  notificationDestination,
  printLabel,
  updateA1Batch,
  removeBatch,
  getDataSaveFilter,
  createDataSaveFilter,
  deleteDataSaveFilter,
  orderPrint,
  shipmentPrint,
  shipmentPrintPackingSlip,
  shipmentStore,
  shipmentRefund,
  shipmentPrintReturnLabel,
  shipmentStorePackingSlip,
  setDropOff,
  markAsDroppedOff,
  releasedOrder,
  t86Documents,
  uploadT86Documents,
  deleteT86Documents,
  checkExistsDocument,
  createTicket,
  getTickets,
  getTicketDetail,
  updateTicket,
  deleteTicket,
  postComments,
}
