import {useFormik} from 'formik'
import moment from 'moment'
import React, {useCallback, useState} from 'react'
import {Container, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {ConfirmActionModal} from '../../../../../gori/components'
import {useCancelToken, UseYupValidate} from '../../../../../gori/hooks'
import {useA1BatchesProvider} from '../../../../../gori/providers'
import {Button, InputDate, ValidationErrorModal} from '../../../../../gori/widgets'
import {A1BatchService} from '../../../a1'

type Props = {
  show: boolean
  handleClose: any
  handleSuccess: any
}

const ConfirmA1BatchModal: React.FC<Props> = ({show, handleClose, handleSuccess}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {dateYup} = UseYupValidate()
  const [validationErrors, setValidationErrors] = useState<any>()
  const {a1Batch} = useA1BatchesProvider()
  const [showModal, setShowModal] = useState<{
    confirmPickUpdate: boolean
    confirmJourneyStatus: boolean
  }>({
    confirmPickUpdate: false,
    confirmJourneyStatus: false,
  })
  const [dataModal, setDataModal] = useState<{
    confirmJourneyStatus: {
      title?: string | null
      message?: any
      callback: any
    }
  }>({
    confirmJourneyStatus: {title: null, message: null, callback: null},
  })
  const validateSchema = Yup.object({
    apu_at: dateYup('PICK_UP_DATE', moment(a1Batch?.epu_at).format()),
  })

  const formik = useFormik({
    initialValues: {
      apu_at: a1Batch?.apu_at || a1Batch?.epu_at || null,
      confirm: false,
    },
    enableReinitialize: true,
    validationSchema: validateSchema,
    onSubmit: async (values, {setSubmitting}) => {
      if (!values.confirm) {
        formik.setFieldValue('confirm', true)
        setDataModal((prev) => ({
          ...prev,
          confirmJourneyStatus: {
            title: intl.formatMessage({id: 'CONFIRM_PICKUP_DATE'}),
            callback: () => formik.handleSubmit(),
          },
        }))
        return setShowModal((prev) => ({...prev, confirmJourneyStatus: true}))
      }

      const config = {cancelToken: newCancelToken()}
      try {
        setSubmitting(true)
        const {message}: any = await A1BatchService.confirmPickUpDate(a1Batch.id, values, config)
        if (message) {
          toast.success(message)
          handleSuccess()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleConfirmJourneyStatus = useCallback(async () => {
    setShowModal((prev) => ({...prev, confirmJourneyStatus: false}))
    await dataModal.confirmJourneyStatus.callback()
    await setDataModal((prev) => ({
      ...prev,
      confirmJourneyStatus: {
        callback: null,
      },
    }))
  }, [dataModal.confirmJourneyStatus])

  const handleCloseConfirmJourneyStatus = useCallback(() => {
    setDataModal((prev) => ({
      ...prev,
      confirmJourneyStatus: {
        title: null,
        message: null,
        callback: null,
      },
    }))
    setShowModal((prev) => ({...prev, confirmJourneyStatus: false}))
  }, [])

  return (
    <>
      {showModal.confirmJourneyStatus && (
        <ConfirmActionModal
          show={showModal.confirmJourneyStatus}
          classNameDialog='mw-750px'
          title={
            dataModal?.confirmJourneyStatus?.title ||
            intl.formatMessage({id: 'UPDATE_JOURNEY_STATUS'})
          }
          message={
            dataModal?.confirmJourneyStatus?.message || (
              <span className='fw-bold text-center'>
                <p>{intl.formatMessage({id: 'ARE_YOU_SURE_YOU_WANT_TO_PROCEED'})}</p>
                <p>
                  {intl.formatMessage({
                    id: 'THIS_ACTION_CANNOT_BE_UNDONE_PLEASE_REVIEW_CAREFULLY_BEFORE_PROCEEDING',
                  })}
                </p>
              </span>
            )
          }
          messagePrimary={intl.formatMessage({id: 'CONFIRM'})}
          messageSecondary={intl.formatMessage({id: 'CANCEL'})}
          handlePrimaryAction={handleConfirmJourneyStatus}
          handleSecondaryAction={handleCloseConfirmJourneyStatus}
          handleClose={handleCloseConfirmJourneyStatus}
        />
      )}
      <Modal
        id='gori_modal_confirm_pick_up_date'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-600px h-auto'
        show={show}
        backdrop='static'
        onHide={() => {
          handleClose()
        }}
      >
        {validationErrors && (
          <ValidationErrorModal
            handleClose={() => {
              setValidationErrors(undefined)
            }}
            response={validationErrors}
          />
        )}
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({
                id: a1Batch?.apu_at ? 'CHANGE_PICKUP_DATE' : 'CONFIRM_PICKUP_DATE',
              })}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className='d-flex'>
                <InputDate
                  className={'col-8'}
                  label={intl.formatMessage({id: 'PICK_UP'})}
                  labelClassName='col-4 col-form-label fs-5 fw-bolder text-dark'
                  name='apu_at'
                  formik={formik}
                  min={moment(a1Batch?.epu_at)}
                  views={['year', 'month', 'day', 'hours', 'minutes']}
                />
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='row'>
              <div className='d-flex justify-content-end'>
                <Button
                  className='btn btn-secondary me-3'
                  label={intl.formatMessage({id: 'CANCEL'})}
                  event={() => handleClose()}
                />
                <Button
                  className='btn btn-primary'
                  label={intl.formatMessage({id: 'CONFIRM'})}
                  loading={formik.isSubmitting}
                  event={formik.handleSubmit}
                />
              </div>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {ConfirmA1BatchModal}
