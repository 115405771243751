import {isEmpty} from 'lodash'
import React, {useCallback, useContext, useEffect, useState} from 'react'
import {CommonService} from '../../app/modules/common'
import {StorageHelpers} from '../helpers/StorageHelpers'
import {useAuth} from './AuthProvider'

const STATES_GLOBAL_STORAGE_KEY =
  process.env.REACT_APP_STATES_GLOBAL_STORAGE_KEY || 'STATES_GLOBAL_STORAGE_KEY'

export interface StatesGlobalContextModel {
  statesGlobal: any
}

const initStatesGlobalContextPropsStates = {
  statesGlobal: StorageHelpers.getItemLocalStorage(STATES_GLOBAL_STORAGE_KEY),
}

const StatesGlobalContext = React.createContext<StatesGlobalContextModel>(
  initStatesGlobalContextPropsStates
)

const StatesGlobalProvider: React.FC = ({children}) => {
  const {currentUser} = useAuth()
  let [statesGlobal, setStateGlobal] = useState(
    StorageHelpers.getItemLocalStorage(STATES_GLOBAL_STORAGE_KEY)
  )
  const [settingsConfig, setSettingsConfig] = useState<Object>(statesGlobal?.settingsConfig)

  // begin: state global settings config
  const getSettingsConfig = useCallback(async () => {
    try {
      const {data} = await CommonService.getSettingsConfig()
      setSettingsConfig(data)
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (currentUser && isEmpty(settingsConfig)) {
      getSettingsConfig()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSettingsConfig, currentUser])
  // end: state global settings config

  // Update global state when making the first API call.
  useEffect(() => {
    StorageHelpers.setItemLocalStorage(STATES_GLOBAL_STORAGE_KEY, {
      settingsConfig: settingsConfig,
    })

    setStateGlobal(StorageHelpers.getItemLocalStorage(STATES_GLOBAL_STORAGE_KEY))
  }, [settingsConfig])

  return (
    <StatesGlobalContext.Provider
      value={{
        statesGlobal,
      }}
    >
      {children}
    </StatesGlobalContext.Provider>
  )
}

function useStatesGlobal() {
  return useContext(StatesGlobalContext)
}

export {StatesGlobalProvider, useStatesGlobal}
