import {find} from 'lodash'
import {useIntl} from 'react-intl'
import {A1_CONSTANTS} from '../../core/constants'

export const StatusViewOnly = ({status}: {status: string}) => {
  const intl = useIntl()

  const foundRelease =
    find(Object.values(A1_CONSTANTS.OPTIONS_RELEASE_STATUS), {
      value: status,
    }) || null

  return (
    <div className='d-flex justify-content-center align-items-center text-dark min-w-150px'>
      {foundRelease ? intl.formatMessage({id: foundRelease?.label}) : ''}
    </div>
  )
}
