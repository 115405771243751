import clsx from 'clsx'
import {useFormik} from 'formik'
import {find} from 'lodash'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import * as Yup from 'yup'
import {DatePicker, KTSVG} from '../../../../../../../gori/components'
import {DEFAULT_PAGE, DEFAULT_PAGE_SIZE, FORMAT_DATE} from '../../../../../../../gori/constants'
import {convertUserTimeZone} from '../../../../../../../gori/helpers'
import {useCancelToken, UsePermission, UseYupValidate} from '../../../../../../../gori/hooks'
import {useA1BatchesProvider, useAuth} from '../../../../../../../gori/providers'
import {
  Button,
  InputSearch,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../../../../gori/widgets'
import {A1_CONSTANTS} from '../../../../core/constants'
import {A1Helpers} from '../../../../core/helpers'
import {A1BatchService} from '../../../../core/requests'
import {TextEditor} from './TextEditor'

type Props = {
  show: boolean
  handleCloseModal: () => void
}

const TicketList: React.FC<Props> = ({show, handleCloseModal}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()
  const {newCancelToken, isCancel} = useCancelToken()
  const {isOrigin, isDestination} = UsePermission()
  const {a1Batch, setTicketForum} = useA1BatchesProvider()
  const {stringYup} = UseYupValidate()
  const [tableData, setTableData] = useState<{
    tickets: any
    pagination: any
    hasLoadMore: boolean
  }>({tickets: [], pagination: {}, hasLoadMore: false})
  const [loading, setLoading] = useState<{
    first: boolean
    getTickets: boolean
    createTicket: boolean
  }>({
    first: true,
    getTickets: false,
    createTicket: false,
  })
  const [validationErrors, setValidationErrors] = useState<any>()
  const pageCurrent = useRef(Number(DEFAULT_PAGE))

  const journeyStatusLargerOrEqualAirDelivery = useMemo(
    () =>
      A1Helpers.checkJourneyStatus({
        statusCurrent: a1Batch?.journey_status,
        statusCheck: A1_CONSTANTS.OPTIONS_JOURNEY_STATUS.AIR_DELIVERY.value[0],
      }),
    [a1Batch?.journey_status]
  )

  const tabs = useMemo(
    () =>
      [
        {key: 'customer', label: intl.formatMessage({id: 'SHIPBAE_USER'})},
        isDestination && {key: 'origin', label: intl.formatMessage({id: 'ORIGIN_PARTNER'})},
        journeyStatusLargerOrEqualAirDelivery &&
          isOrigin && {
            key: 'destination',
            label: intl.formatMessage({id: 'DESTINATION_PARTNER'}),
          },
      ].filter(Boolean),
    [intl, isDestination, isOrigin, journeyStatusLargerOrEqualAirDelivery]
  )

  const tabName = useMemo(() => {
    return (searchParams.get('recipient') || 'customer') as 'customer' | 'origin' | 'destination'
  }, [searchParams])

  const handleClose = () => {
    formik.resetForm()
    handleCloseModal()
  }

  const configParams = useMemo(
    () => ({
      page: DEFAULT_PAGE,
      page_size: DEFAULT_PAGE_SIZE,
      s: searchParams.get('s') || null,
      status: searchParams.get('status') || null,
      recipient: tabName,
      date_ticket_from: searchParams.get('date_ticket_from') || null,
      date_ticket_to: searchParams.get('date_ticket_to') || null,
    }),
    [searchParams, tabName]
  )

  const setTable = useCallback(async () => {
    pageCurrent.current = Number(DEFAULT_PAGE)

    try {
      setLoading((prev) => ({...prev, first: true}))
      const config = {
        params: configParams,
        cancelToken: newCancelToken(),
      }
      const response: any = await A1BatchService.getTickets(a1Batch?.id, config)
      if (response) {
        setTableData({...response, hasLoadMore: response.pagination.total_pages > 1})
      }
    } catch (error: any) {
      if (isCancel(error)) return
    } finally {
      setLoading((prev) => ({...prev, first: false}))
    }
  }, [a1Batch?.id, configParams, isCancel, newCancelToken])

  const fetchMore = async () => {
    if (pageCurrent.current >= tableData?.pagination?.total_pages) {
      setTableData((prev) => ({...prev, hasLoadMore: false}))
      return
    }

    pageCurrent.current++

    try {
      setLoading((prev) => ({...prev, getTickets: true}))
      const config = {
        params: {...configParams, page: pageCurrent.current},
        cancelToken: newCancelToken(),
      }
      const response: any = await A1BatchService.getTickets(a1Batch.id, config)
      if (response) {
        setTableData((prev) => ({...prev, tickets: [...prev.tickets, ...response.tickets]}))
      }
    } catch (error: any) {
      if (isCancel(error)) return
    } finally {
      setLoading((prev) => ({...prev, getTickets: false}))
    }
  }

  useEffect(() => {
    if (show && a1Batch?.id) {
      setTable()
    }
  }, [a1Batch?.id, setTable, show])

  const filterStatus = useMemo(() => {
    const statusValue = searchParams.get('status')
    const status: any =
      find(Object.values(A1_CONSTANTS.TICKET_STATUS), {value: statusValue}) || null

    return {
      label: intl.formatMessage({id: status ? status.label : 'ALL'}),
      value: statusValue || '',
    }
  }, [intl, searchParams])

  const handleFilterStatus = useCallback(
    (e) => {
      if (e.value) {
        searchParams.set('status', e.value)
      } else {
        searchParams.delete('status')
      }
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams]
  )

  const handleViewDetail = (ticket) => {
    searchParams.set('id', ticket.id.toString())
    setSearchParams(searchParams)
    setTicketForum((prev) => ({...prev, detailId: ticket.id.toString()}))
  }

  const validateSchema = useMemo(
    () =>
      Yup.object({
        hasAddTicket: Yup.boolean(),
        name: Yup.string().when('hasAddTicket', {
          is: true,
          then: stringYup(255, 'TICKET_NAME', true),
          otherwise: stringYup(255, 'TICKET_NAME', false),
        }),
        content: Yup.string().when('hasAddTicket', {
          is: true,
          then: stringYup('no_limit', 'TICKET_DESCRIPTION', true),
          otherwise: stringYup('no_limit', 'TICKET_DESCRIPTION', false),
        }),
        attachments: Yup.array(),
      }),
    [stringYup]
  )

  const formik = useFormik({
    initialValues: {hasAddTicket: false, name: '', content: '', attachments: []},
    validationSchema: validateSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleCreateTicket(values)
    },
  })

  const handleAddTicket = () => {
    formik.setFieldValue('hasAddTicket', true)
  }

  const handleCancelTicket = () => {
    formik.setFieldValue('hasAddTicket', false)
    formik.resetForm()
  }

  const handleCreateTicket = async (values) => {
    try {
      setLoading((prev) => ({...prev, createTicket: true}))
      const payload = new FormData()
      payload.append('recipient', tabName)
      payload.append('batch_id', a1Batch?.id)
      payload.append('name', values.name)
      payload.append('content', values.content)
      formik.values.attachments.forEach((file) => payload.append('attachments[]', file))
      const res = await A1BatchService.createTicket(payload, {cancelToken: newCancelToken()})
      if (res) {
        setTable()
        formik.resetForm()
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setLoading((prev) => ({...prev, createTicket: false}))
    }
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <div>
        <div className='ticket-forum-title d-flex justify-content-between align-items-center border-bottom border-gray-300 p-8'>
          <div className='h-40px d-flex align-items-center'>
            <div className='h-40px w-10px bg-primary rounded-2 me-4' />
            <span className='fw-bolder fs-1 m-0'> {intl.formatMessage({id: 'TICKET_FORUM'})}</span>
          </div>
          <div className='cursor-pointer' onClick={handleClose}>
            <KTSVG
              path='/media/gori/common/delete.svg'
              className='svg-icon-4 text-gray-700 text-hover-dark'
            />
          </div>
        </div>
        <div className='ticket-forum-body d-flex flex-column gap-4 p-8'>
          <div className='d-flex flex-column flex-md-row justify-content-between flex-wrap gap-4'>
            <div className='d-flex align-items-center position-relative my-1'>
              <InputSearch
                keySearch='s'
                className='min-w-250px bg-white border-gray-300'
                placeholder={intl.formatMessage({id: 'SEARCH_TICKET'})}
              />
            </div>
            <div className='d-flex flex-column flex-sm-row align-items-center gap-4'>
              <SelectFormik
                className='w-100 fw-bold fs-5'
                value={filterStatus}
                placeholder={intl.formatMessage({id: 'STATUS'})}
                onChange={handleFilterStatus}
                options={Object.values(A1_CONSTANTS.TICKET_STATUS)}
                hasUseIntl={true}
                emptyDefault={false}
              />
              <DatePicker
                dateFrom='date_ticket_from'
                dateTo='date_ticket_to'
                name={intl.formatMessage({id: 'DATE'})}
                className='w-100 h-45px border-gray-300'
              />
            </div>
          </div>
          <div className='d-flex gap-4 sticky-top bg-white mx-0'>
            {tabs.map(
              (tab) =>
                tab && (
                  <h4
                    key={tab?.key}
                    className={clsx('pb-2 px-4 cursor-pointer text-hover-dark', {
                      'border-bottom border-dark': tabName === tab?.key,
                      'text-muted fw-normal': tabName !== tab?.key,
                    })}
                    onClick={() => {
                      searchParams.set('recipient', tab?.key)
                      setSearchParams(searchParams)
                    }}
                  >
                    {tab?.label}
                  </h4>
                )
            )}
          </div>
          {formik.values.hasAddTicket && (
            <div className='border border-gray-300 rounded-2 d-flex flex-column gap-4 p-4'>
              <div>
                <InputTextFormik
                  label={intl.formatMessage({id: 'TICKET_NAME'})}
                  formik={formik}
                  name='name'
                  required
                />
              </div>
              <TextEditor formik={formik} name='content' fileName='attachments' />
              <div className='flex-fill d-flex gap-4'>
                <Button
                  divClassName='flex-fill'
                  className='btn btn-white text-gray-700 text-hover-dark border border-gray-300 w-100 fw-bolder'
                  label={intl.formatMessage({id: 'CANCEL'})}
                  event={handleCancelTicket}
                />
                <Button
                  divClassName='flex-fill'
                  className='btn btn-primary w-100 fw-bolder'
                  label={intl.formatMessage({id: 'CREATE_TICKET'})}
                  event={formik.handleSubmit}
                  loading={loading.createTicket}
                />
              </div>
            </div>
          )}
          <div className='flex-fill d-flex flex-column scroll-y scrollbar-width-none'>
            {loading.first ? (
              Array.from({length: 3}).map((_, idx) => (
                <div
                  className='d-flex justify-content-between align-items-center p-4 mt-4 border border-gray-300 rounded-2'
                  key={idx}
                >
                  <div className='col-10 d-flex flex-column'>
                    <div className='fs-1 col-6 placeholder rounded-2 bg-secondary' />
                    <span className='fs-1 col-4 placeholder placeholder-sm rounded-2 bg-secondary mt-2' />
                  </div>
                  <span className='fs-1 col-2 placeholder placeholder-sm rounded-2 bg-secondary mt-2' />
                </div>
              ))
            ) : tableData?.tickets.length > 0 ? (
              <InfiniteScroll
                dataLength={tableData?.tickets}
                next={fetchMore}
                hasMore={tableData?.hasLoadMore}
                height={'75vh'}
                loader={
                  loading.getTickets && (
                    <div className='d-flex justify-content-center'>
                      <span className='spinner-border' />
                    </div>
                  )
                }
                endMessage={
                  <h4 className='d-flex justify-content-center mt-4 text-muted'>
                    {intl.formatMessage({id: 'NO_MORE_TICKETS_AVAILABLE_TO_LOAD'})}
                  </h4>
                }
              >
                {tableData?.tickets.map((ticket: any, idx) => {
                  const ticketDate = convertUserTimeZone(
                    ticket.created_at,
                    currentUser,
                    FORMAT_DATE.DATE_TIME
                  )
                  return (
                    <div
                      className='position-relative d-flex justify-content-between align-items-center p-4 mt-4 border border-gray-300 rounded-2 cursor-pointer border-hover-primary bg-hover-light-primary'
                      key={idx}
                      onClick={() => handleViewDetail(ticket)}
                    >
                      {ticket?.total_comment_unread > 0 && (
                        <div className='w-10px h-100 position-absolute start-0 rounded-start bg-primary' />
                      )}
                      <div className='ms-2 me-10 text-truncate'>
                        <h4 className='text-truncate'>
                          {intl.formatMessage({id: 'TICKET'})} #{ticket?.id} - {ticket?.name}
                        </h4>
                        <span className='text-muted'>
                          {`${intl.formatMessage({id: 'CREATED_ON'})} ${ticketDate}`}
                        </span>
                      </div>
                      <div>
                        <span className={`badge-${ticket?.status}-ticket rounded-pill px-2`}>
                          {intl.formatMessage({id: ticket?.status.toUpperCase()})}
                        </span>
                      </div>
                    </div>
                  )
                })}
              </InfiniteScroll>
            ) : (
              <div className='flex-fill mt-2 border border-gray-300 rounded-3 d-flex  flex-column justify-content-center align-items-center gap-5'>
                <div className='d-flex justify-content-between align-items-center p-4 mt-4 border border-gray-300 rounded-2 w-50'>
                  <div className='col-10 d-flex flex-column'>
                    <div className='fs-1 col-6 placeholder rounded-2 bg-secondary' />
                    <span className='fs-1 col-4 placeholder placeholder-sm rounded-2 bg-secondary mt-2' />
                  </div>
                  <span className='fs-1 col-2 placeholder placeholder-sm rounded-2 bg-secondary mt-2' />
                </div>
                <h1>{intl.formatMessage({id: 'NO_TICKET_FOUND'})}</h1>
              </div>
            )}
          </div>
          <Button
            className='btn btn-primary w-100'
            label={intl.formatMessage({id: 'ADD_NEW_TICKET'})}
            disabled={formik.values.hasAddTicket}
            event={handleAddTicket}
          />
        </div>
      </div>
    </>
  )
}

export {TicketList}
