import clsx from 'clsx'
import {useFormik} from 'formik'
import React from 'react'
import {Container, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useStatesGlobal} from '../../../../../gori/providers'
import {Button, InputTextArea, SelectFormik} from '../../../../../gori/widgets'
import {A1_CONSTANTS} from '../../../a1'

type Props = {
  data: {
    type: string
    reason: string
    first_release_status: string
  } | null
  show: boolean
  isDisabled?: boolean
  handleClose: any
}

const ReasonModal: React.FC<Props> = ({data, show, handleClose, isDisabled = false}) => {
  const intl = useIntl()
  const {statesGlobal} = useStatesGlobal()

  const formik = useFormik({
    initialValues: {
      type: data?.type || null,
      reason: data?.reason || null,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {},
  })

  return (
    <Modal
      id='gori_modal_hold_order_reason'
      tabIndex={-1}
      aria-hidden='true'
      centered
      dialogClassName='mw-600px h-auto'
      show={show}
      backdrop='static'
      onHide={handleClose}
    >
      <div className='modal-content'>
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>
            {intl.formatMessage({
              id: `${data?.first_release_status?.toUpperCase()}_ORDER_REASON`,
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div>
              <SelectFormik
                emptyDefault={false}
                formik={formik}
                name='type'
                disabled={isDisabled}
                className={clsx('fw-bold fs-5')}
                label={intl.formatMessage({
                  id: `CAUSE_OF_${
                    data?.first_release_status === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.HOLD.value
                      ? 'HOLDING'
                      : 'REJECTION'
                  }`,
                })}
                options={
                  data?.first_release_status
                    ? statesGlobal.settingsConfig?.[data?.first_release_status]
                    : []
                }
                required
              />
              <InputTextArea
                className='mt-4'
                formik={formik}
                name='reason'
                required
                disabled={isDisabled}
              />
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div className='row'>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-3'
                label={intl.formatMessage({id: 'CLOSE'})}
                event={handleClose}
              />
            </div>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export {ReasonModal}
