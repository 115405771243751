import {useMemo} from 'react'
import {PERMISSION} from '../constants'
import {useAuth} from '../providers'

export const UsePermission = () => {
  const {currentUser} = useAuth()

  const permission = useMemo(() => {
    const origin = currentUser?.role === PERMISSION.ORIGIN
    const destination = currentUser?.role === PERMISSION.DESTINATION
    return {origin, destination}
  }, [currentUser?.role])

  return {
    isOrigin: permission.origin,
    isDestination: permission.destination,
    role: currentUser?.role,
  }
}
