import clsx from 'clsx'
import {compact, isEmpty} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../gori/components'
import {FORMAT_DATE} from '../../../../../gori/constants'
import {convertUserTimeZone} from '../../../../../gori/helpers'
import {UsePermission} from '../../../../../gori/hooks'
import {useA1BatchesProvider, useAuth} from '../../../../../gori/providers'
import {A1_CONSTANTS} from '../../../a1'

type Props = {
  handleEditConfirmPickupDate?: () => void
  handleEditJourneyInfo?: () => void
}

const JourneyInfoCard: React.FC<Props> = ({handleEditConfirmPickupDate, handleEditJourneyInfo}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {a1Batch} = useA1BatchesProvider()
  const [showPopover, setShowPopover] = useState<Record<string, boolean>>({})
  const {isOrigin, isDestination} = UsePermission()
  const {PICK_UP_DROP_OFF, AIR_DELIVERY} = A1_CONSTANTS.OPTIONS_JOURNEY_STATUS

  const formattedDate = useCallback(
    (date) => (date ? convertUserTimeZone(date, currentUser, FORMAT_DATE.DATE_TIME) : null),
    [currentUser]
  )

  const formatNumber = useCallback(
    ({number = 0, showDecimal = true}: {number: string | number; showDecimal?: boolean}) => {
      const numberFormat = Number(number)
      if (!numberFormat && !showDecimal) return null
      return intl.formatNumber(numberFormat, {
        style: 'decimal',
        minimumFractionDigits: showDecimal ? 2 : 0,
        maximumFractionDigits: 2,
      })
    },
    [intl]
  )

  const showInfo = useMemo(() => {
    const flights = a1Batch?.journey_flight_infos?.reduce(
      (acc, flight) => ({
        ...acc,
        [flight.type]: flight,
      }),
      {}
    )

    return {
      flights,
      label: {
        go: intl.formatMessage({id: flights?.origin?.atd ? 'ATD' : 'ETD'}),
        arrive: intl.formatMessage({id: flights?.destination?.ata ? 'ATA' : 'ETA'}),
      },
    }
  }, [a1Batch?.journey_flight_infos, intl])

  const showJourneyInfoButton = useMemo(() => {
    return (
      (isOrigin && PICK_UP_DROP_OFF.value[2] === a1Batch?.journey_status) ||
      (isDestination &&
        AIR_DELIVERY.value[0] === a1Batch?.journey_status &&
        showInfo?.flights?.origin?.atd &&
        showInfo?.flights?.destination?.ata)
    )
  }, [
    AIR_DELIVERY.value,
    PICK_UP_DROP_OFF.value,
    a1Batch?.journey_status,
    isDestination,
    isOrigin,
    showInfo?.flights?.destination?.ata,
    showInfo?.flights?.origin?.atd,
  ])

  const showShipFrom = useMemo(() => {
    if (isEmpty(a1Batch?.from)) return null
    const {street1, street2, city, state, zip} = a1Batch?.from
    const addressTo = compact([
      street1,
      street2,
      city,
      state && zip ? `${state} ${zip}` : state || zip,
    ]).join(', ')
    return addressTo
  }, [a1Batch.from])

  const toolTipModal = useCallback(
    ({
      message,
      className = '',
      messageTooltip = message,
      classNameTooltip = '',
      placement = 'bottom',
    }) => (
      <OverlayTrigger
        placement={placement}
        show={showPopover.id}
        overlay={
          <Popover
            className='mw-500px p-5'
            onMouseEnter={() => setShowPopover((prev) => ({...prev, tooltip: true}))}
            onMouseLeave={() => setShowPopover((prev) => ({...prev, tooltip: false}))}
          >
            <span className={clsx('fw-bold', classNameTooltip)}>{messageTooltip}</span>
          </Popover>
        }
      >
        <span
          className={clsx('cursor-pointer text-truncate', className)}
          onMouseEnter={() => setShowPopover((prev) => ({...prev, tooltip: true}))}
          onMouseLeave={() => setShowPopover((prev) => ({...prev, tooltip: false}))}
        >
          {message}
        </span>
      </OverlayTrigger>
    ),
    [showPopover]
  )

  return (
    <div className='card me-xxl-4 h-100'>
      <div className='card-header border-0 fw-bolder d-flex justify-content-between align-items-center'>
        <span className='fs-3 required'>{intl.formatMessage({id: 'JOURNEY_INFO'})}</span>
        {a1Batch?.apu_at && PICK_UP_DROP_OFF.value[0] === a1Batch?.journey_status && (
          <div
            className='btn btn-sm btn-light-primary rounded-pill'
            onClick={handleEditConfirmPickupDate}
          >
            {intl.formatMessage({id: 'EDIT'})}
          </div>
        )}
        {showJourneyInfoButton && (
          <div
            className='btn btn-sm btn-light-primary rounded-pill'
            onClick={handleEditJourneyInfo}
          >
            {intl.formatMessage({id: 'EDIT'})}
          </div>
        )}
      </div>
      <div className='card-body bg-light rounded-bottom d-flex flex-column flex-md-row'>
        <div className='col-12 col-md-6'>
          <div className='d-flex pe-md-6 text-primary fs-5 fs-xxl-7 text-center fw-bolder'>
            <div className='col-4'>{intl.formatMessage({id: 'JOURNEY'})}</div>
            <div className='col-4'>{intl.formatMessage({id: 'FLIGHT_NUMBER'})}</div>
            <div className='col-4'>
              {showInfo.label.go} &#8226; {showInfo.label.arrive}
            </div>
          </div>
          <div className='d-flex flex-column min-h-150px mh-275px scroll-y pe-md-4 mt-4'>
            {!isEmpty(a1Batch?.journey_flight_infos) &&
              a1Batch?.journey_flight_infos?.map((flight: any, index) => {
                return (
                  <div
                    className={clsx('d-flex flex-column', {
                      'flex-column-fluid': index !== a1Batch?.journey_flight_infos?.length - 1,
                    })}
                    key={index}
                  >
                    <div className='col-4 text-center'>
                      <div className='fs-1 fw-boldest'>{flight?.airport}</div>
                    </div>
                    {index !== a1Batch?.journey_flight_infos?.length - 1 && (
                      <div className='d-flex flex-fill'>
                        <div className='col-4 d-flex flex-column justify-content-center align-items-center'>
                          <div className='border-dashed border-gray-600 border-2 flex-fill h-100' />
                        </div>
                        <div className='col-4 d-flex align-items-center justify-content-center fw-bolder gap-2'>
                          <KTSVG
                            path='/media/gori/a1/flight.svg'
                            className='svg-icon-1 text-gray-700'
                          />
                          {toolTipModal({
                            message: flight?.flight_number,
                            className: 'fs-4 fs-xxl-6',
                          })}
                        </div>
                        <div className='col-4 d-flex flex-column justify-content-center text-center fs-6 fs-xxl-8 fw-bold gap-2 text-gray-700'>
                          <span>{formattedDate(flight?.atd || flight?.etd)}</span>
                          {flight?.est_duration && (
                            <span className='fw-boldest text-dark'>{flight?.est_duration}h</span>
                          )}
                          <span>
                            {formattedDate(
                              a1Batch?.journey_flight_infos[index + 1]?.ata ||
                                a1Batch?.journey_flight_infos[index + 1]?.eta
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
          </div>
        </div>
        <span className='border border-secondary my-8 my-md-n8' />
        <div className='col-12 col-md-6 ps-6 fs-5 fs-xxl-7 fw-bold min-h-150px mh-275px scroll-y pe-2'>
          <div className='d-flex flex-wrap'>
            <div className='col-12 d-flex border-bottom border-secondary mt-10 flex-fill mb-1'>
              <span className='text-nowrap'>{intl.formatMessage({id: 'SHIP_FROM'})}</span>
              {showShipFrom &&
                toolTipModal({
                  message: showShipFrom,
                  className: 'ms-3 fs-5 fs-xxl-7 fw-boldest w-75',
                })}
            </div>
            <div className='col-12 d-flex border-bottom border-secondary mt-10 flex-fill mb-1'>
              <span className='text-nowrap'>{intl.formatMessage({id: 'A1_SERVICE'})}</span>
              {a1Batch?.service &&
                toolTipModal({
                  message: a1Batch?.service,
                  className: 'ms-3 fs-5 fs-xxl-7 fw-boldest',
                })}
            </div>
            <span className='col-12 col-md-6 d-flex justify-content-between border-bottom border-secondary mt-10 flex-fill mb-1'>
              <span className='text-nowrap'>{intl.formatMessage({id: 'MAWB'})}</span>
              {toolTipModal({
                message: a1Batch?.mawb,
                className: 'ms-3 fw-boldest pe-0 pe-md-4',
              })}
            </span>
            <span className='col-12 col-md-6 d-flex justify-content-between border-bottom border-secondary mt-10 flex-fill mb-1'>
              {toolTipModal({message: intl.formatMessage({id: 'OF_PALLETS'})})}
              {toolTipModal({
                message: formatNumber({number: a1Batch?.pallets, showDecimal: false}),
                className: 'ms-3 fw-boldest',
              })}
            </span>
            <span className='col-12 col-md-6 d-flex justify-content-between border-bottom border-secondary mt-10 flex-fill mb-1'>
              {toolTipModal({message: intl.formatMessage({id: 'OF_PARCELS'})})}
              {toolTipModal({
                message: formatNumber({number: a1Batch?.parcels, showDecimal: false}),
                className: 'ms-3 fw-boldest pe-0 pe-md-4',
              })}
            </span>
            <span className='col-12 col-md-6 d-flex justify-content-between border-bottom border-secondary mt-10 flex-fill mb-1'>
              {toolTipModal({message: intl.formatMessage({id: 'KGS'})})}
              {toolTipModal({
                message: formatNumber({number: a1Batch?.weight}),
                className: 'ms-3 fw-boldest',
              })}
            </span>
            {isOrigin && (
              <>
                <span className='col-12 col-md-6 d-flex justify-content-between border-bottom border-secondary mt-10 flex-fill mb-1'>
                  {toolTipModal({message: intl.formatMessage({id: 'PICK_UP'})})}
                  {toolTipModal({
                    message: formatNumber({number: a1Batch?.origin_pick_up}),
                    className: 'ms-3 fw-boldest',
                  })}
                  <span className='text-muted me-0 me-md-4 ms-2'>
                    {intl.formatMessage({id: 'USD'})}
                  </span>
                </span>
                <span className='col-12 col-md-6 d-flex justify-content-between border-bottom border-secondary mt-10 flex-fill mb-1'>
                  {toolTipModal({message: intl.formatMessage({id: 'CONSOLIDATION'})})}
                  {toolTipModal({
                    message: formatNumber({number: a1Batch?.origin_consolidation}),
                    className: 'ms-3 fw-boldest',
                  })}
                  <span className='text-muted ms-2'>{intl.formatMessage({id: 'USD'})}</span>
                </span>
                <span className='col-12 col-md-6 d-flex justify-content-between border-bottom border-secondary mt-10 flex-fill mb-1'>
                  {toolTipModal({message: intl.formatMessage({id: 'FREIGHT'})})}
                  {toolTipModal({
                    message: formatNumber({number: a1Batch?.origin_freight}),
                    className: 'ms-3 fw-boldest',
                  })}
                  <span className='text-muted me-0 me-md-4 ms-2'>
                    {intl.formatMessage({id: 'USD'})}
                  </span>
                </span>
              </>
            )}
            {isDestination && (
              <>
                <span className='col-12 col-md-6 d-flex justify-content-between border-bottom border-secondary mt-10 flex-fill mb-1'>
                  {toolTipModal({message: intl.formatMessage({id: 'CLEARANCE'})})}
                  {toolTipModal({
                    message: formatNumber({number: a1Batch?.destination_clearance}),
                    className: 'ms-3 fw-boldest',
                  })}
                  <span className='text-muted me-0 me-md-4 ms-2'>
                    {intl.formatMessage({id: 'USD'})}
                  </span>
                </span>
                <span className='col-12 col-md-6 d-flex justify-content-between border-bottom border-secondary mt-10 flex-fill mb-1'>
                  {toolTipModal({message: intl.formatMessage({id: 'DECONSOLIDATION'})})}
                  {toolTipModal({
                    message: formatNumber({number: a1Batch?.destination_deconsolidation}),
                    className: 'ms-3 fw-boldest',
                  })}
                  <span className='text-muted ms-2'>{intl.formatMessage({id: 'USD'})}</span>
                </span>
                <span className='col-12 col-md-6 d-flex justify-content-between border-bottom border-secondary mt-10 flex-fill mb-1'>
                  {toolTipModal({message: intl.formatMessage({id: 'TRANSFER'})})}
                  {toolTipModal({
                    message: formatNumber({number: a1Batch?.destination_transfer}),
                    className: 'ms-3 fw-boldest',
                  })}
                  <span className='text-muted me-0 me-md-4 ms-2'>
                    {intl.formatMessage({id: 'USD'})}
                  </span>
                </span>
              </>
            )}
            {a1Batch?.[`${isOrigin ? 'origin' : 'destination'}_misc`]?.length > 0 &&
              a1Batch?.[`${isOrigin ? 'origin' : 'destination'}_misc`]?.map((misc, index) => {
                return (
                  <span
                    className={clsx(
                      'col-12 col-md-6 d-flex justify-content-between border-bottom border-secondary mt-10 flex-fill mb-1'
                    )}
                    key={index}
                  >
                    {toolTipModal({message: misc.title})}
                    {toolTipModal({
                      message: formatNumber({number: misc.amount}),
                      className: 'ms-3 fw-boldest',
                    })}
                    <span className={clsx('text-muted ms-2', {'me-0 me-md-4': index % 2 !== 0})}>
                      {intl.formatMessage({id: 'USD'})}
                    </span>
                  </span>
                )
              })}
            {a1Batch?.[`${isOrigin ? 'origin' : 'destination'}_misc`]?.length % 2 === 0 && (
              <span className='col-12 col-md-6'></span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export {JourneyInfoCard}
