import {useIntl} from 'react-intl'
import {A1_CONSTANTS} from '../../core/constants'
import {SelectFormik} from '../../../../../gori/widgets'
import {useMemo, useState} from 'react'
import {StatusViewOnly} from './StatusViewOnly'

type Option = {
  label: string
  value: string
  visible: boolean
  typeMassUpdate?: string
  releaseStatus?: string
}

type UpdateStatusProps = {
  row: any
  onChangeCallback: (option: Option) => void
  isUpdating?: boolean
}

export const UpdateStatus = ({row, onChangeCallback}: UpdateStatusProps) => {
  const intl = useIntl()

  const hasRejected = useMemo(
    () => A1_CONSTANTS.OPTIONS_RELEASE_STATUS.HOLD.value.includes(row.original.release_status),
    [row.original.release_status]
  )

  const hasReleased = useMemo(
    () =>
      [
        A1_CONSTANTS.OPTIONS_RELEASE_STATUS.HOLD.value,
        A1_CONSTANTS.OPTIONS_RELEASE_STATUS.REJECTED.value,
      ].includes(row.original.release_status),
    [row.original.release_status]
  )

  const hasDestroyed = useMemo(
    () => A1_CONSTANTS.OPTIONS_RELEASE_STATUS.REJECTED.value.includes(row.original.release_status),
    [row.original.release_status]
  )

  const options: Option[] = useMemo(
    () => [
      {
        label: intl.formatMessage({id: A1_CONSTANTS.OPTIONS_RELEASE_STATUS.HOLD.label}),
        value: A1_CONSTANTS.OPTIONS_RELEASE_STATUS.HOLD.value,
        visible: row.original.release_status === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.HOLD.value,
      },
      {
        label: intl.formatMessage({id: A1_CONSTANTS.OPTIONS_RELEASE_STATUS.REJECTED.label}),
        value: A1_CONSTANTS.OPTIONS_RELEASE_STATUS.REJECTED.value,
        visible: row.original.release_status === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.REJECTED.value,
      },
      {
        label: intl.formatMessage({id: A1_CONSTANTS.OPTIONS_RELEASE_STATUS.REJECTED.label}),
        value: A1_CONSTANTS.OPTIONS_RELEASE_STATUS.REJECTED.value,
        visible: hasRejected,
        typeMassUpdate: A1_CONSTANTS.MASS_UPDATE.RELEASE_STATUS.value,
        releaseStatus: A1_CONSTANTS.OPTIONS_RELEASE_STATUS.REJECTED.value,
      },
      {
        label: intl.formatMessage({
          id: A1_CONSTANTS.OPTIONS_RELEASE_STATUS.INFORMAL_RELEASED.label,
        }),
        value: A1_CONSTANTS.OPTIONS_RELEASE_STATUS.INFORMAL_RELEASED.value,
        visible: hasReleased,
        typeMassUpdate: A1_CONSTANTS.MASS_UPDATE.RELEASE_STATUS.value,
        releaseStatus: A1_CONSTANTS.OPTIONS_RELEASE_STATUS.INFORMAL_RELEASED.value,
      },
      {
        label: intl.formatMessage({id: A1_CONSTANTS.OPTIONS_RELEASE_STATUS.FORMAL_RELEASED.label}),
        value: A1_CONSTANTS.OPTIONS_RELEASE_STATUS.FORMAL_RELEASED.value,
        visible: hasReleased,
        typeMassUpdate: A1_CONSTANTS.MASS_UPDATE.RELEASE_STATUS.value,
        releaseStatus: A1_CONSTANTS.OPTIONS_RELEASE_STATUS.FORMAL_RELEASED.value,
      },
      {
        label: intl.formatMessage({id: A1_CONSTANTS.OPTIONS_RELEASE_STATUS.MANUAL_RELEASED.label}),
        value: A1_CONSTANTS.OPTIONS_RELEASE_STATUS.MANUAL_RELEASED.value,
        visible: hasReleased,
        typeMassUpdate: A1_CONSTANTS.MASS_UPDATE.RELEASE_STATUS.value,
        releaseStatus: A1_CONSTANTS.OPTIONS_RELEASE_STATUS.MANUAL_RELEASED.value,
      },
      {
        label: intl.formatMessage({id: A1_CONSTANTS.OPTIONS_RELEASE_STATUS.DESTROYED.label}),
        value: A1_CONSTANTS.OPTIONS_RELEASE_STATUS.DESTROYED.value,
        visible: hasDestroyed,
        typeMassUpdate: A1_CONSTANTS.MASS_UPDATE.RELEASE_STATUS.value,
        releaseStatus: A1_CONSTANTS.OPTIONS_RELEASE_STATUS.DESTROYED.value,
      },
    ],
    [hasDestroyed, hasRejected, hasReleased, intl, row.original.release_status]
  )

  const visibleOptions = options.filter((opt) => opt.visible)
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    options.find((opt) => opt.value === row.original.release_status)
  )

  if (!visibleOptions.length) {
    return <StatusViewOnly status={row.original.release_status} />
  }

  return (
    <SelectFormik
      className='w-150px fw-bold fs-5'
      value={selectedOption}
      onChange={(option) => {
        setSelectedOption(option)
        onChangeCallback(option)
      }}
      options={visibleOptions}
      emptyDefault={false}
    />
  )
}
