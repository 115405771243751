import {AxiosRequestConfig} from 'axios'
import axiosConfig from '../../../api/axiosConfig'

const BASE_URL = '/profile'
const SETTINGS_URL = '/settings'
const URL_SHIPPING_ADDRESS = '/shipping-addresses'

// BEGIN: User
const getUser = (config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.get(`${BASE_URL}`, config)
}
// END: User

// BEGIN: Settings
const getSettingsConfig = (config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.get(`${SETTINGS_URL}`, config)
}

// const updateLanguage = (userId, payload, config?: AxiosRequestConfig | undefined) => {
//   return axiosConfig.put(`/users/profile/general/${userId}`, payload, config)
// }
// END: Settings

// BEGIN: addresses
const getAddress = (config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.get(`${URL_SHIPPING_ADDRESS}`, config)
}

const saveAddress = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL_SHIPPING_ADDRESS}`, payload, config)
}

const editAddress = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.put(`${URL_SHIPPING_ADDRESS}`, payload, config)
}

const validateAddress = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return axiosConfig.post(`${URL_SHIPPING_ADDRESS}/validate-address`, payload, config)
}
// END: addresses

export const CommonService = {
  // -----User--------
  getUser,
  // -----User--------
  // -----Settings--------
  getSettingsConfig,
  // updateLanguage,
  // -----Settings--------
  // -----Address--------
  getAddress,
  saveAddress,
  editAddress,
  validateAddress,
  // -----Address--------
}
