import clsx from 'clsx'
import {useFormik} from 'formik'
import {find, get, map, upperCase} from 'lodash'
import React, {useEffect, useMemo, useState} from 'react'
import {Container, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {ConfirmActionModal} from '../../../../../../gori/components'
import {useCancelToken, UseYupValidate} from '../../../../../../gori/hooks'
import {useA1BatchesProvider, useStatesGlobal} from '../../../../../../gori/providers'
import {
  Button,
  InputTextArea,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../../../gori/widgets'
import {A1_CONSTANTS, A1BatchService} from '../../../../a1'

type Props = {
  show: boolean
  data: {
    selectAll: boolean
    selectedList: any
    typeMassUpdate: string
    releaseStatus?: string
  } | null
  handleClose: () => void
  reloadTable: () => void
}

const MassUpdateModal: React.FC<Props> = ({show, data, handleClose, reloadTable}) => {
  const intl = useIntl()
  const {a1Batch} = useA1BatchesProvider()
  const {stringYup} = UseYupValidate()
  const {newCancelToken, isCancel} = useCancelToken()
  const {statesGlobal} = useStatesGlobal()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [loading, setLoading] = useState({update: false})
  const [showModal, setShowModal] = useState({confirm: false})

  const initValidateSchema = useMemo(
    () => ({
      typeMassUpdate: Yup.string(),
      release_status: Yup.string()
        .nullable()
        .when('typeMassUpdate', {
          is: (typeMassUpdate) => typeMassUpdate === A1_CONSTANTS.MASS_UPDATE.RELEASE_STATUS.value,
          then: stringYup(255, 'RELEASE_STATUS'),
        }),
      type: Yup.string()
        .nullable()
        .when('release_status', {
          is: (status) =>
            status === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.HOLD.value ||
            status === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.REJECTED.value,
          then: stringYup(255, 'TYPE'),
        }),
      reason: Yup.string()
        .nullable()
        .when('release_status', {
          is: (status) =>
            status === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.HOLD.value ||
            status === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.REJECTED.value,
          then: stringYup(1000, 'REASON', false),
        }),
    }),
    [stringYup]
  )

  const selected = useMemo(() => {
    let ids: any = {}
    if (data?.selectedList?.selected?.length <= 0 || data?.selectedList?.total?.length <= 0) {
      return {ids, optionsMassUpdate: []}
    }

    const selected = (
      data?.selectAll ? data?.selectedList?.total : data?.selectedList?.selected
    ).reduce(
      (acc, selected) => {
        const releaseStatus = data?.selectAll
          ? selected.release_status
          : get(selected, 'original.release_status')
        const id = data?.selectAll ? selected.id : get(selected, 'original.id')

        if (releaseStatus) {
          acc?.[releaseStatus].push(id)
        } else {
          acc?.no_status.push(id)
        }
        return acc
      },
      {
        no_status: [],
        hold: [],
        rejected: [],
        informal_released: [],
        formal_released: [],
        manual_released: [],
        destroyed: [],
      }
    )

    const optionsMassUpdate = map(A1_CONSTANTS.OPTIONS_RELEASE_STATUS, (option: any) => {
      switch (option.value) {
        case A1_CONSTANTS.OPTIONS_RELEASE_STATUS.HOLD.value:
          ids[option.value] = selected.no_status
          option.isDisabled = selected.no_status.length <= 0
          break
        case A1_CONSTANTS.OPTIONS_RELEASE_STATUS.REJECTED.value:
          ids[option.value] = [...selected.no_status, ...selected.hold]
          option.isDisabled = selected.hold.length > 0 ? false : selected.no_status.length <= 0
          break
        case A1_CONSTANTS.OPTIONS_RELEASE_STATUS.INFORMAL_RELEASED.value:
        case A1_CONSTANTS.OPTIONS_RELEASE_STATUS.FORMAL_RELEASED.value:
        case A1_CONSTANTS.OPTIONS_RELEASE_STATUS.MANUAL_RELEASED.value:
          ids[option.value] = [...selected.no_status, ...selected.hold, ...selected.rejected]
          option.isDisabled =
            (selected.informal_released.length > 0 ||
              selected.formal_released.length > 0 ||
              selected.manual_released.length > 0 ||
              selected.destroyed.length > 0) &&
            selected.no_status.length <= 0 &&
            selected.hold.length <= 0 &&
            selected.rejected.length <= 0
          break
        case A1_CONSTANTS.OPTIONS_RELEASE_STATUS.DESTROYED.value:
          ids[option.value] = selected.rejected
          option.isDisabled = selected.rejected.length <= 0
          break
      }
      return option
    })

    return {ids, optionsMassUpdate}
  }, [data])

  const handleMassUpdate = async ({values, hasPass = false}) => {
    if (!values.release_status) return

    if (!hasPass) {
      if (
        values.release_status === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.FORMAL_RELEASED.value ||
        values.release_status === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.INFORMAL_RELEASED.value ||
        values.release_status === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.MANUAL_RELEASED.value ||
        values.release_status === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.DESTROYED.value
      ) {
        return setShowModal((prev) => ({...prev, confirm: true}))
      }
    }

    const config = {cancelToken: newCancelToken()}
    let payload: any = {
      order_ids: selected.ids?.[values.release_status],
      batch_id: a1Batch.id,
      release_status: values.release_status,
      all: false,
    }

    if (
      values.release_status === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.HOLD.value ||
      values.release_status === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.REJECTED.value
    ) {
      payload.type = values.type
      payload.reason = values.reason
    }

    try {
      setLoading((prev) => ({...prev, update: true}))
      const {message}: any = await A1BatchService.releasedOrder(payload, config)
      if (message) {
        toast.success(message)
        reloadTable()
        handleClose()
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setLoading((prev) => ({...prev, update: false}))
    }
  }

  const formik = useFormik({
    initialValues: {
      typeMassUpdate: data?.typeMassUpdate,
      release_status: data?.releaseStatus || null,
      type: '',
      reason: '',
    },
    validationSchema: Yup.object().shape(initValidateSchema),
    onSubmit: (values) => {
      handleMassUpdate({values})
    },
  })

  const handleSelectReleaseStatus = (e) => {
    formik.setValues((prev) => ({...prev, type: '', reason: ''}))
    formik.setTouched({type: false, reason: false})
  }

  // BEGIN:  Some action auto submit in hold modal
  useEffect(() => {
    if (
      data?.releaseStatus &&
      ![
        A1_CONSTANTS.OPTIONS_RELEASE_STATUS.HOLD.value,
        A1_CONSTANTS.OPTIONS_RELEASE_STATUS.REJECTED.value,
      ].includes(data?.releaseStatus) &&
      formik.values.release_status
    ) {
      handleMassUpdate({values: formik.values, hasPass: false})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // END:  Some action auto submit in hold modal

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => setValidationErrors(undefined)}
          response={validationErrors}
        />
      )}
      {showModal.confirm && (
        <ConfirmActionModal
          show={showModal.confirm}
          title={intl.formatMessage({id: 'RELEASE_ORDER'})}
          message={
            <span className='fw-bold text-center'>
              <p>{intl.formatMessage({id: 'ARE_YOU_SURE_YOU_WANT_TO_PROCEED'})}</p>
              <p>
                {intl.formatMessage({
                  id: 'THIS_ACTION_CANNOT_BE_UNDONE_PLEASE_REVIEW_CAREFULLY_BEFORE_PROCEEDING',
                })}
              </p>
            </span>
          }
          loadingPrimary={loading.update}
          messagePrimary={intl.formatMessage({id: 'CONFIRM'})}
          messageSecondary={intl.formatMessage({id: 'CANCEL'})}
          handlePrimaryAction={() => handleMassUpdate({values: formik.values, hasPass: true})}
          handleSecondaryAction={() => {
            if (data?.releaseStatus) {
              handleClose()
            }
            setShowModal((prev) => ({...prev, confirm: false}))
          }}
          handleClose={() => {
            if (data?.releaseStatus) {
              handleClose()
            }
            setShowModal((prev) => ({...prev, confirm: false}))
          }}
        />
      )}
      <Modal
        id='gori_modal_mass_update_order'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-600px h-auto'
        show={show && !showModal.confirm}
        backdrop='static'
        onHide={handleClose}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix='fw-bolder fs-1'>
              {intl.formatMessage({id: 'MASS_UPDATE'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <Container>
              <div className='d-flex justify-content-between align-items-center'>
                <span className='fs-2 fw-bolder'>
                  {intl.formatMessage({
                    id:
                      find(A1_CONSTANTS.MASS_UPDATE, {value: data?.typeMassUpdate})?.label ||
                      'MASS_UPDATE',
                  })}
                </span>
                <span className='fw-bold text-primary'>
                  {intl.formatMessage(
                    {id: 'THIS_WILL_APPLY_TO_INPUT_SELECTED_ORDERS'},
                    {
                      input: formik.values.release_status
                        ? selected.ids[formik.values.release_status].length
                        : 0,
                    }
                  )}
                </span>
              </div>
              <SelectFormik
                emptyDefault={false}
                formik={formik}
                name='release_status'
                className={clsx('fw-bold fs-5')}
                placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
                options={selected.optionsMassUpdate}
                onChange={handleSelectReleaseStatus}
                required
                hasUseIntl
              />
              {(formik.values.release_status === A1_CONSTANTS.OPTIONS_RELEASE_STATUS.HOLD.value ||
                formik.values.release_status ===
                  A1_CONSTANTS.OPTIONS_RELEASE_STATUS.REJECTED.value) && (
                <div className='mt-8'>
                  <SelectFormik
                    emptyDefault={false}
                    formik={formik}
                    name='type'
                    placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
                    className={clsx('fw-bold fs-5')}
                    label={intl.formatMessage({
                      id: `CAUSE_OF_${
                        formik.values.release_status ===
                        A1_CONSTANTS.OPTIONS_RELEASE_STATUS.HOLD.value
                          ? 'HOLDING'
                          : 'REJECTION'
                      }`,
                    })}
                    options={statesGlobal.settingsConfig?.[formik.values.release_status]}
                    required
                  />
                  <InputTextArea
                    className='mt-4'
                    formik={formik}
                    name='reason'
                    placeholder={
                      formik.values?.release_status &&
                      intl.formatMessage(
                        {
                          id: 'INPUT_THE_DETAIL_REASON_FOR_INPUT_ORDER',
                        },
                        {
                          input: intl
                            .formatMessage({
                              id: upperCase(formik.values.release_status),
                            })
                            .toLowerCase(),
                        }
                      )
                    }
                    required
                  />
                </div>
              )}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                event={handleClose}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'UPDATE'})}
                event={formik.handleSubmit}
                disabled={loading.update}
                loading={loading.update}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {MassUpdateModal}
