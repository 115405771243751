import clsx from 'clsx'
import {isEqual} from 'lodash'
import moment from 'moment'
import {FC, useEffect, useMemo, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {KTSVG} from '../components'
import {FORMAT_DATE, DEFAULT_PAGE} from '../constants'
import {Button} from '../widgets'

type Props = {
  dateStart?: number
  dateFrom?: string
  dateTo?: string
  name?: string
  className?: string
  disabled?: boolean
  alwaysShowLabel?: boolean
}

const DatePicker: FC<Props> = ({
  dateStart = 29,
  dateFrom = 'date_from',
  dateTo = 'date_to',
  name = 'Date',
  className,
  disabled = false,
  alwaysShowLabel = true,
}) => {
  const intl = useIntl()
  const [searchParams, setSearchParams] = useSearchParams()
  const [shouldRender, setShouldRender] = useState(true)

  const handleReset = () => {
    setShouldRender(false)

    setTimeout(() => {
      setShouldRender(true)
    }, 0)
  }

  useEffect(() => {
    handleReset()
  }, [intl])

  const initDate = useMemo(
    () => ({
      start: moment().subtract(dateStart, 'days').startOf('day'),
      end: moment().endOf('day'),
    }),
    [dateStart]
  )
  const valueDateStart = useMemo(() => {
    return searchParams.get(dateFrom) && moment(searchParams.get(dateFrom))
  }, [dateFrom, searchParams])
  const valueDateEnd = useMemo(() => {
    return searchParams.get(dateTo) && moment(searchParams.get(dateTo))
  }, [dateTo, searchParams])

  const [searchDate, setSearchDate] = useState<any>({
    start: valueDateStart || initDate.start,
    end: valueDateEnd || initDate.end,
  })

  const formatDate = ({start, end}) => {
    return {
      start: start.format(FORMAT_DATE.DATE),
      end: end.format(FORMAT_DATE.DATE),
    }
  }

  const isEqualInit = useMemo(() => {
    if (!alwaysShowLabel) return false
    const initDateFormat = formatDate(initDate)
    const dateCurrent = formatDate({
      start: moment(searchParams.get(dateFrom)),
      end: moment(searchParams.get(dateTo)),
    })

    if (dateCurrent.start === 'Invalid date' || dateCurrent.end === 'Invalid date') {
      return true
    }

    return isEqual(initDateFormat, dateCurrent)
  }, [alwaysShowLabel, dateFrom, dateTo, initDate, searchParams])

  const labelDate = useMemo(() => {
    const dateCurrent = formatDate({
      start: moment(searchParams.get(dateFrom)),
      end: moment(searchParams.get(dateTo)),
    })
    const initDateFormat = formatDate(initDate)

    return dateCurrent.start !== 'Invalid date' && dateCurrent.end !== 'Invalid date'
      ? `${dateCurrent.start} - ${dateCurrent.end}`
      : alwaysShowLabel
      ? `${initDateFormat.start} - ${initDateFormat.end}`
      : undefined
  }, [alwaysShowLabel, dateFrom, dateTo, initDate, searchParams])

  useEffect(() => {
    setSearchDate({
      start: valueDateStart || initDate.start,
      end: valueDateEnd || initDate.end,
    })
  }, [initDate.end, initDate.start, valueDateStart, valueDateEnd])

  const handleUpdateDate = (start: any, end: any) => {
    start = start.format()
    end = end.format()
    setSearchDate({
      start: start,
      end: end,
    })

    searchParams.set(dateFrom, start)
    searchParams.set(dateTo, end)
    searchParams.set('page', DEFAULT_PAGE)
    setSearchParams(searchParams)
  }

  const handleDeleteField = async () => {
    const date = {start: initDate.start.format(), end: initDate.end.format()}
    setSearchDate(date)
    searchParams.delete(dateFrom)
    searchParams.delete(dateTo)
    setSearchParams(searchParams)
    handleReset()
  }

  return (
    <>
      {shouldRender ? (
        <DateRangePicker
          initialSettings={{
            locale: {
              customRangeLabel: intl.formatMessage({id: 'CUSTOM_RANGE'}),
              cancelLabel: intl.formatMessage({id: 'CANCEL'}),
              applyLabel: intl.formatMessage({id: 'APPLY'}),
              fromLabel: intl.formatMessage({id: 'FROM'}),
              toLabel: intl.formatMessage({id: 'TO'}),
              daysOfWeek: [
                intl.formatMessage({id: 'SU'}),
                intl.formatMessage({id: 'MO'}),
                intl.formatMessage({id: 'TU'}),
                intl.formatMessage({id: 'WE'}),
                intl.formatMessage({id: 'TH'}),
                intl.formatMessage({id: 'FR'}),
                intl.formatMessage({id: 'SA'}),
              ],
              monthNames: [
                intl.formatMessage({id: 'JANUARY'}),
                intl.formatMessage({id: 'FEBRUARY'}),
                intl.formatMessage({id: 'MARCH'}),
                intl.formatMessage({id: 'APRIL'}),
                intl.formatMessage({id: 'MAY'}),
                intl.formatMessage({id: 'JUNE'}),
                intl.formatMessage({id: 'JULY'}),
                intl.formatMessage({id: 'AUGUST'}),
                intl.formatMessage({id: 'SEPTEMBER'}),
                intl.formatMessage({id: 'OCTOBER'}),
                intl.formatMessage({id: 'NOVEMBER'}),
                intl.formatMessage({id: 'DECEMBER'}),
              ],
            },
            startDate: searchDate.start,
            endDate: searchDate.end,
            opens: 'left',
            ranges: {
              [`${intl.formatMessage({id: 'TODAY'})}`]: [moment(), moment()],
              [`${intl.formatMessage({id: 'YESTERDAY'})}`]: [
                moment().subtract(1, 'days'),
                moment().subtract(1, 'days'),
              ],
              [`${intl.formatMessage({id: 'LAST_7_DAYS'})}`]: [
                moment().subtract(6, 'days'),
                moment(),
              ],
              [`${intl.formatMessage({id: 'LAST_30_DAYS'})}`]: [
                moment().subtract(29, 'days'),
                moment(),
              ],
              [`${intl.formatMessage({id: 'LAST_MONTH'})}`]: [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
          onCallback={(start, end) => {
            handleUpdateDate(start, end)
          }}
        >
          <div
            className={clsx(
              'btn btn-sm rounded-2 d-flex justify-content-center align-items-center border btn-active-light-primary ',
              className,
              {
                'border border-2 border-primary rounded-2': labelDate && !isEqualInit,
                'pe-none disabled': disabled,
              }
            )}
          >
            <div className='fw-bold'>
              <span className='fw-bolder fs-5 text-gray-600'>{name}</span>
            </div>
            {labelDate ? (
              <>
                <div className='text-nowrap ms-2 ps-2 border-start border-2 border-secondary'>
                  {labelDate}
                </div>
                {!isEqualInit && (
                  <Button
                    event={handleDeleteField}
                    className='text-hover-danger border-0 ms-2 bg-transparent rounded-circle bg-hover-white py-1'
                  >
                    <KTSVG
                      path='/media/gori/common/delete.svg'
                      className='opacity-50 m-0'
                      svgClassName='mh-10px'
                    />
                  </Button>
                )}
              </>
            ) : (
              <i className='bi bi-caret-down-fill ms-2'></i>
            )}
          </div>
        </DateRangePicker>
      ) : (
        <div
          className={clsx(
            'btn btn-sm rounded-2 d-flex justify-content-center align-items-center border border-2 border-light btn-active-light-primary ',
            className,
            {
              'border border-2 border-primary rounded-2': labelDate,
              'pe-none disabled': disabled,
            }
          )}
        >
          <div className='fw-bold'>
            <span className='fw-bolder fs-5 text-gray-600'>{name}</span>
          </div>
          {labelDate ? (
            <>
              <div className='text-nowrap ms-2 ps-2 border-start border-2 border-secondary'>
                {labelDate}
              </div>
              {!isEqualInit && (
                <Button
                  event={handleDeleteField}
                  className='text-hover-danger border-0 ms-2 bg-transparent rounded-circle bg-hover-white py-1'
                >
                  <KTSVG
                    path='/media/gori/common/delete.svg'
                    className='opacity-50 m-0'
                    svgClassName='mh-10px'
                  />
                </Button>
              )}
            </>
          ) : (
            <i className='bi bi-caret-down-fill ms-2'></i>
          )}
        </div>
      )}
    </>
  )
}

export {DatePicker}
