import clsx from 'clsx'
import {isEmpty} from 'lodash'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {ConfirmActionModal, KTSVG} from '../../../../../../../gori/components'
import {FORMAT_DATE} from '../../../../../../../gori/constants'
import {convertUserTimeZone} from '../../../../../../../gori/helpers'
import {useCancelToken, UsePermission} from '../../../../../../../gori/hooks'
import {useA1BatchesProvider, useAuth} from '../../../../../../../gori/providers'
import {Button, ValidationErrorModal} from '../../../../../../../gori/widgets'
import {A1BatchService} from '../../../../core/requests'
import {CommentTicket} from './CommentTicket'

type Props = {
  handleCloseModal: () => void
  handleBack: () => void
}

const TicketDetail: React.FC<Props> = ({handleBack, handleCloseModal}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {newCancelToken, isCancel} = useCancelToken()
  const {role} = UsePermission()
  const {ticketForum} = useA1BatchesProvider()
  const [ticketData, setTicketData] = useState<{
    id: number
    name: string
    status: 'open' | 'resolved'
    comments: any
    created_by: string
    created_at: string
  }>()
  const [loading, setLoading] = useState<{
    getTicket: boolean
    refresh: boolean
    send: boolean
    deleteTicket: boolean
    resolveTicket: boolean
  }>({
    getTicket: false,
    refresh: false,
    send: false,
    deleteTicket: false,
    resolveTicket: false,
  })
  const [validationErrors, setValidationErrors] = useState<any>()
  const [showModal, setShowModal] = useState({confirm: false})
  const [dataModal, setDataModal] = useState<{confirm: {title: string | null; callback: any}}>({
    confirm: {title: null, callback: null},
  })
  const lastCommentRef = useRef<HTMLDivElement | null>(null)

  const ticketDate = useMemo(() => {
    return ticketData?.created_at
      ? convertUserTimeZone(ticketData?.created_at, currentUser, FORMAT_DATE.DATE_TIME)
      : 'DD/MM/YYYY | hh:mm'
  }, [currentUser, ticketData])

  const getTicketDetail = useCallback(
    async ({refresh = false}) => {
      if (refresh) {
        lastCommentRef.current = null
      }

      try {
        setLoading((prev) => ({...prev, [refresh ? 'refresh' : 'getTicket']: true}))
        const {data} = await A1BatchService.getTicketDetail(ticketForum?.detailId, {
          cancelToken: newCancelToken(),
        })
        setTicketData(data)
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, [refresh ? 'refresh' : 'getTicket']: false}))
      }
    },
    [isCancel, newCancelToken, ticketForum?.detailId]
  )

  useEffect(() => {
    if ((!loading.getTicket || !loading.refresh) && lastCommentRef.current) {
      lastCommentRef.current.scrollIntoView({behavior: 'smooth'})
    }
  }, [loading.getTicket, loading.refresh, ticketData])

  useEffect(() => {
    getTicketDetail({refresh: false})
  }, [getTicketDetail])

  const handleDeleteTicket = useCallback(
    async ({hasPass = false}) => {
      if (!hasPass) {
        setDataModal((prev) => ({
          ...prev,
          confirm: {
            title: intl.formatMessage({id: 'DELETE_TICKET'}),
            callback: () => handleDeleteTicket({hasPass: true}),
          },
        }))
        return setShowModal((prev) => ({...prev, confirm: true}))
      }

      try {
        setLoading((prev) => ({...prev, deleteTicket: true}))
        const res: any = await A1BatchService.deleteTicket(ticketForum?.detailId, {
          cancelToken: newCancelToken(),
        })
        if (res) {
          toast.success(res.message)
          handleBack()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, deleteTicket: false}))
      }
    },
    [handleBack, intl, isCancel, newCancelToken, ticketForum?.detailId]
  )

  const handleResolveTicket = useCallback(
    async ({hasPass = false}) => {
      if (!hasPass) {
        setDataModal((prev) => ({
          ...prev,
          confirm: {
            title: intl.formatMessage({id: 'RESOLVE_TICKET'}),
            callback: () => handleResolveTicket({hasPass: true}),
          },
        }))
        return setShowModal((prev) => ({...prev, confirm: true}))
      }

      try {
        setLoading((prev) => ({...prev, resolveTicket: true}))
        const res: any = await A1BatchService.updateTicket(
          ticketForum?.detailId,
          {status: 'resolved'},
          {
            cancelToken: newCancelToken(),
          }
        )
        if (res) {
          toast.success(res.message)
          getTicketDetail({refresh: true})
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, resolveTicket: false}))
      }
    },
    [getTicketDetail, intl, isCancel, newCancelToken, ticketForum?.detailId]
  )

  const handleCloseConfirm = useCallback(() => {
    setShowModal((prev) => ({...prev, confirm: false}))
  }, [])

  const handleConfirmAction = useCallback(async () => {
    handleCloseConfirm()
    await dataModal.confirm.callback()
    await setDataModal((prev) => ({...prev, confirm: {title: null, callback: null}}))
  }, [dataModal.confirm, handleCloseConfirm])

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            handleBack()
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      {showModal.confirm && (
        <ConfirmActionModal
          show={showModal.confirm}
          title={dataModal.confirm.title || ''}
          message={
            <span className='fw-bold text-center'>
              <p>{intl.formatMessage({id: 'ARE_YOU_SURE_YOU_WANT_TO_PROCEED'})}</p>
              <p>
                {intl.formatMessage({
                  id: 'THIS_ACTION_CANNOT_BE_UNDONE_PLEASE_REVIEW_CAREFULLY_BEFORE_PROCEEDING',
                })}
              </p>
            </span>
          }
          messagePrimary={intl.formatMessage({id: 'CONFIRM'})}
          messageSecondary={intl.formatMessage({id: 'CANCEL'})}
          handlePrimaryAction={handleConfirmAction}
          handleSecondaryAction={handleCloseConfirm}
          handleClose={handleCloseConfirm}
        />
      )}
      <div>
        <div className='ticket-forum-title d-flex justify-content-between align-items-center border-bottom border-gray-300 p-8'>
          <div className='h-40px d-flex align-items-center'>
            <div className='cursor-pointer' onClick={handleBack}>
              <KTSVG
                path='/media/gori/arrows/arrowLeft2.svg'
                className='svg-icon-1 text-dark text-hover-primary'
              />
            </div>
          </div>
          <div className='cursor-pointer' onClick={handleCloseModal}>
            <KTSVG
              path='/media/gori/common/delete.svg'
              className='svg-icon-4 text-gray-700 text-hover-dark'
            />
          </div>
        </div>
        <div className='ticket-forum-body d-flex flex-column gap-4 p-8'>
          {loading.getTicket || isEmpty(ticketData) ? (
            <div className='h-100 d-flex flex-column border border-gray-300 rounded-2'>
              <div className='d-flex justify-content-between align-items-center p-4 border-bottom border-gray-300'>
                <div className='col-10 d-flex flex-column'>
                  <div className='fs-1 col-6 placeholder rounded-2 bg-secondary' />
                  <span className='fs-1 col-4 placeholder placeholder-sm rounded-2 bg-secondary mt-2' />
                </div>
                <span className='fs-1 col-2 placeholder placeholder-sm rounded-2 bg-secondary mt-2' />
              </div>
              <div className='scroll-y flex-fill'>
                {Array.from({length: 3}).map((_, index) => (
                  <div className='bg-light rounded-2 p-4 m-4' key={index}>
                    <div className='fs-1 col-5 placeholder rounded-2 bg-secondary' />
                    <div className='fs-4 col-9 placeholder rounded-2 bg-secondary mt-2' />
                  </div>
                ))}
              </div>
              <div className='border-top border-gray-300 p-6'>
                <div className='position-relative'>
                  <textarea className='form-control py-4 ps-4 pe-30 resize-none h-50px pe-none' />
                  <div className='d-flex position-absolute top-50 end-0 translate-middle-y'>
                    {['attachments', 'send'].map((icon, idx) => (
                      <div key={idx} className='mx-4'>
                        <KTSVG
                          path={`/media/gori/a1/${icon}.svg`}
                          className='svg-icon-1 text-dark'
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='h-100 d-flex flex-column border border-gray-300 rounded-2'>
              <div className='d-flex justify-content-between align-items-center p-4 border-bottom border-gray-300'>
                <div>
                  <h4 className='text-break me-4'>
                    {intl.formatMessage({id: 'TICKET'})} #{ticketData?.id} - {ticketData?.name}
                  </h4>
                  <span className='text-muted'>
                    {`${intl.formatMessage({id: 'CREATED_ON'})} ${ticketDate}`}
                  </span>
                </div>
                <div className='d-flex gap-4'>
                  <Button
                    className={clsx('btn btn-sm p-0 text-hover-primary cursor-pointer', {
                      'text-primary': loading.refresh,
                    })}
                    event={() => getTicketDetail({refresh: true})}
                  >
                    <KTSVG
                      path='/media/gori/a1/sync.svg'
                      className={clsx('svg-icon-1 text-hover-primary', {
                        'spin_reverse text-primary': loading.refresh,
                      })}
                    />
                    {intl.formatMessage({id: 'REFRESH'})}
                  </Button>
                  <span className={`badge-${ticketData?.status}-ticket rounded-pill px-2`}>
                    {intl.formatMessage({id: ticketData?.status.toUpperCase()})}
                  </span>
                </div>
              </div>
              {ticketData?.created_by === role && ticketData?.status === 'open' && (
                <div className='d-flex col-12 py-4 border-bottom border-gray-300'>
                  <Button
                    loadingText={intl.formatMessage({id: 'DELETE_TICKET'})}
                    label={intl.formatMessage({id: 'DELETE_TICKET'})}
                    divClassName='col-6 d-flex justify-content-center'
                    className='flex-fill mx-4 btn bg-hover-light-primary border border-gray-300 fw-bolder'
                    event={handleDeleteTicket}
                    loading={loading.deleteTicket}
                  />
                  <Button
                    loadingText={intl.formatMessage({id: 'RESOLVE_TICKET'})}
                    label={intl.formatMessage({id: 'RESOLVE_TICKET'})}
                    divClassName='col-6 d-flex justify-content-center'
                    className='flex-fill mx-4 btn btn-primary fw-bolder'
                    event={handleResolveTicket}
                    loading={loading.resolveTicket}
                  />
                </div>
              )}
              <div className='scroll-y flex-fill'>
                {loading?.refresh
                  ? Array.from({length: 3}).map((_, index) => (
                      <div className='bg-light rounded-2 p-4 m-4' key={index}>
                        <div className='fs-1 col-5 placeholder rounded-2 bg-secondary' />
                        <div className='fs-4 col-9 placeholder rounded-2 bg-secondary mt-2' />
                      </div>
                    ))
                  : ticketData?.comments.map((comment, index) => {
                      const isFirst = index === 0
                      const isLast = index === ticketData?.comments.length - 1

                      return (
                        <div
                          className={clsx('rounded-2 p-4 m-4', {
                            'bg-light': !isFirst,
                            'bg-secondary': isFirst,
                          })}
                          key={index}
                          ref={isLast ? lastCommentRef : null}
                        >
                          {comment?.content &&
                            (isFirst ? (
                              <div
                                className='mt-3 fs-5 text-break text-pre-wrap'
                                dangerouslySetInnerHTML={{__html: comment?.content}}
                              />
                            ) : (
                              <>
                                <span>
                                  <strong className='fs-5 me-2'>
                                    {intl.formatMessage({id: comment.created_by?.toUpperCase()})}
                                  </strong>
                                  {convertUserTimeZone(
                                    comment.created_at,
                                    currentUser,
                                    FORMAT_DATE.DATE_TIME
                                  )}
                                </span>
                                <div className='mt-3 fs-5 text-break text-pre-wrap'>
                                  {comment?.content}
                                </div>
                              </>
                            ))}
                          <div>
                            {!comment?.content && (
                              <span>
                                <strong className='fs-5 me-2'>
                                  {intl.formatMessage({id: comment.created_by?.toUpperCase()})}
                                </strong>
                                {convertUserTimeZone(
                                  comment.created_at,
                                  currentUser,
                                  FORMAT_DATE.DATE_TIME
                                )}
                              </span>
                            )}
                            {comment?.attachments?.map((attachment, idx) => (
                              <div key={idx} className='my-4'>
                                <a
                                  className='d-inline-flex align-items-center bg-secondary p-2 rounded-2 text-gray-800 text-hover-dark'
                                  href={attachment?.file_path}
                                  download={attachment?.file_name}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  <span className='me-4'>
                                    <KTSVG
                                      path='/media/gori/a1/file.svg'
                                      className='svg-icon-3 text-dark'
                                    />
                                  </span>
                                  <span className='mw-500px text-truncate'>
                                    {attachment?.file_name}
                                  </span>
                                  <KTSVG
                                    path='/media/gori/a1/fileDownload.svg'
                                    className='svg-icon-3 ms-2'
                                  />
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    })}
              </div>
              {ticketData?.status === 'open' && (
                <CommentTicket reloadTicketDetail={() => getTicketDetail({refresh: true})} />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export {TicketDetail}
