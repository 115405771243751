import clsx from 'clsx'
import {isEmpty} from 'lodash'
import {FC, useCallback, useEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import {KTSVG} from '../../components'
import {DEFAULT_PAGE} from '../../constants'
import {Button} from '../form/Button'

type Props = {
  placeholder?: string
  className?: string
  keySearch?: string
}

const InputSearch: FC<Props> = ({
  placeholder = '',
  keySearch = 'search_text',
  className = null,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchText, setSearchText] = useState(searchParams.get(keySearch) || '')

  const handleSearch = useCallback(
    (e) => {
      if (isEmpty(searchText)) {
        searchParams.delete(keySearch)
      } else {
        searchParams.set(keySearch, searchText)
      }

      if (e.key === 'Enter' || isEmpty(searchText)) {
        searchParams.set('page', DEFAULT_PAGE)
        setSearchParams(searchParams)
      }
    },
    [keySearch, searchParams, searchText, setSearchParams]
  )

  useEffect(() => {
    setSearchText(searchParams.get(keySearch) || '')
  }, [keySearch, searchParams])

  return (
    <>
      <KTSVG path='/media/gori/common/magnify.svg' className='svg-icon-1 position-absolute ms-4' />
      <input
        type='text'
        autoComplete='off'
        className={clsx('form-control form-control-solid px-14', className)}
        placeholder={placeholder}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyUp={(e) => handleSearch(e)}
        value={searchText}
      />
      {searchText && (
        <Button
          event={() => {
            setSearchText('')
            searchParams.delete(keySearch)
            setSearchParams(searchParams)
          }}
          divClassName='d-flex justify-content-center align-items-center'
          className='text-hover-dark border-0 bg-transparent rounded-circle py-1 position-absolute end-0 me-4'
        >
          <KTSVG
            path='/media/gori/common/delete.svg'
            className='opacity-50 m-0'
            svgClassName='mh-10px'
          />
        </Button>
      )}
    </>
  )
}

export {InputSearch}
