import {isEmpty} from 'lodash'
import {NavigateFunction} from 'react-router-dom'

export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  url = url.split('?')[0]
  const current = getCurrentUrl(pathname)
  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  return current.indexOf(url) > -1
}

export function checkIsActiveSubRoute(subRoute: Array<string>, pathname: string) {
  const current = getCurrentUrl(pathname)
  if (!current || isEmpty(subRoute)) {
    return false
  }

  if (subRoute.indexOf(current) > -1) {
    return true
  } else {
    for (let i = 0; i < subRoute.length; i++) {
      if (current.includes(subRoute[i])) {
        return true
      }
    }
  }
}

export const globalRouter = {navigate: null} as {
  navigate: null | NavigateFunction
}

export const getFilteredSearchParams = (
  searchParams: URLSearchParams,
  excludeKeys: string[] = []
): string => {
  return [...searchParams.entries()]
    .filter(([key]) => !excludeKeys.includes(key))
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
}
