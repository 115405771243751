import clsx from 'clsx'
import {compact, find, isEmpty, omit} from 'lodash'
import React, {useMemo, useState} from 'react'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../gori/components'
import {FORMAT_DATE} from '../../../../../gori/constants'
import {convertUserTimeZone} from '../../../../../gori/helpers'
import {useA1BatchesProvider, useAuth} from '../../../../../gori/providers'
import {A1_CONSTANTS, A1Helpers, HoldOrderModal} from '../../../a1'

type Props = {
  data: {
    label?: string
    value?: any
    children?: any
  }
}

const StatusRow = ({label}) => (
  <div className='col-2 min-w-100px'>
    <div className='px-2'>{label}</div>
  </div>
)

const DataRow: React.FC<Props> = ({data: {value = [], children = null}}) => (
  <div className='col-2 min-w-100px'>
    {children ||
      value.map(({date, time, decoration = false}, idx) => (
        <div className={clsx('px-2 fw-bold', {'mt-2': idx !== 0})} key={idx}>
          {date && (
            <span className={clsx({'text-gray-400 text-decoration-line-through': decoration})}>
              {date}
            </span>
          )}
          {time && <div className='mt-1 text-gray-600'>{time}</div>}
        </div>
      ))}
  </div>
)

const JourneyStatusCard: React.FC = () => {
  const intl = useIntl()
  const {a1Batch} = useA1BatchesProvider()
  const {currentUser} = useAuth()
  const {setReloadTableDetail} = useA1BatchesProvider()
  const [hasShow, setHasShow] = useState<{holdModal: boolean}>({holdModal: false})
  const [showPopover, setShowPopover] = useState<boolean>(false)

  const customDateTime = (date, format = FORMAT_DATE.DATE_TIME) => {
    if (isEmpty(date)) return {date: null, time: null}
    const dateTime = convertUserTimeZone(date, currentUser, format).split(' ')
    return {date: dateTime[0], time: dateTime.slice(1).join(' ') || null}
  }

  const flightInFo = useMemo(
    () => ({
      origin: find(a1Batch?.journey_flight_infos, {type: 'origin'}),
      destination: find(a1Batch?.journey_flight_infos, {type: 'destination'}),
    }),
    [a1Batch?.journey_flight_infos]
  )

  const pickupAddress = useMemo(() => {
    const {street1, street2, city, state, zip, country} = a1Batch?.pickup_address
    return {
      short: compact([city, country]).join(', '),
      full: compact([street1, street2, city, state && zip ? `${state} ${zip}` : state || zip]).join(
        ', '
      ),
    }
  }, [a1Batch?.pickup_address])

  const pickupModal = useMemo(
    () => (
      <OverlayTrigger
        placement='right'
        show={showPopover}
        overlay={
          <Popover
            className='mw-500px'
            onMouseEnter={() => setShowPopover(true)}
            onMouseLeave={() => setShowPopover(false)}
          >
            <div className='p-5 rounded-2 shadow fw-bold'>{pickupAddress.full}</div>
          </Popover>
        }
      >
        <div
          className='text-primary text-decoration-underline cursor-pointer'
          onMouseEnter={() => setShowPopover(true)}
          onMouseLeave={() => setShowPopover(false)}
        >
          {pickupAddress.short}
        </div>
      </OverlayTrigger>
    ),
    [pickupAddress, showPopover]
  )

  const journeyStatusLargerOrEqualCustoms = useMemo(
    () =>
      A1Helpers.checkJourneyStatus({
        statusCurrent: a1Batch?.journey_status,
        statusCheck: A1_CONSTANTS.OPTIONS_JOURNEY_STATUS.CUSTOMS.value[0],
      }),
    [a1Batch?.journey_status]
  )

  const holdModal = useMemo(
    () => journeyStatusLargerOrEqualCustoms && a1Batch?.held_orders > 0 && a1Batch?.held_orders,
    [journeyStatusLargerOrEqualCustoms, a1Batch?.held_orders]
  )

  const delivery = useMemo(
    () =>
      A1Helpers.checkJourneyStatus({
        statusCurrent: a1Batch?.journey_status,
        statusCheck: A1_CONSTANTS.OPTIONS_JOURNEY_STATUS.DELIVERY_STATUS.value[0],
      }),
    [a1Batch?.journey_status]
  )

  const deliveryLabel = useMemo(() => {
    const trackingStatus = a1Batch?.tracking_status || {}
    const carriers = {
      USPS: {name: 'USPS', value: Number(trackingStatus.usps)},
      UPS: {name: 'UPS', value: Number(trackingStatus.ups)},
      FEDEX: {name: 'FedEx', value: trackingStatus.fedex},
    }

    const formatStatus = (name: string, value: number | undefined) => {
      if (Number.isNaN(value)) return null
      return (
        <div className='text-primary text-start mb-2' key={name}>
          {name}:{' '}
          {value === 100 ? (
            <span className='text-nowrap'>
              {intl.formatMessage({id: 'DELIVERED'})}
              <KTSVG path='/media/gori/a1/verify.svg' className='svg-icon-2 text-teal ms-1' />
            </span>
          ) : (
            `${value}%`
          )}
        </div>
      )
    }

    return (
      <div>
        {carriers.USPS.value === 100 && carriers.UPS.value === 100
          ? formatStatus(`${carriers.USPS.name}/${carriers.UPS.name}`, 100)
          : Object.values(omit(carriers, 'FEDEX')).map((carrier) =>
              formatStatus(carrier.name, carrier.value)
            )}
        {!isEmpty(carriers.FEDEX.value) && (
          <div className='text-primary text-start mb-2'>
            {carriers.FEDEX.name}: {carriers.FEDEX.value}
          </div>
        )}
      </div>
    )
  }, [a1Batch?.tracking_status, intl])

  const estimatedPickup = customDateTime(a1Batch?.epu_at, FORMAT_DATE.DATE)
  const actualPickup = customDateTime(a1Batch?.apu_at, FORMAT_DATE.DATE)

  const dataRowsFirst = [
    {
      label: a1Batch?.date ? intl.formatMessage({id: 'LABEL_CREATED'}) : null,
      value: [customDateTime(a1Batch?.date)],
    },
    {
      label: a1Batch?.epu_at || a1Batch?.apu_at ? intl.formatMessage({id: 'PICK_UP_DATE'}) : null,
      value: [
        a1Batch?.apu_at
          ? estimatedPickup?.date !== actualPickup?.date
            ? {...estimatedPickup, decoration: true}
            : null
          : {...estimatedPickup},
        customDateTime(a1Batch?.apu_at),
      ]?.filter(Boolean),
    },
    {
      label:
        flightInFo?.origin?.etd || flightInFo?.destination?.eta
          ? `${intl.formatMessage({id: 'ETD'})}/${intl.formatMessage({id: 'ETA'})}`
          : null,
      value: [
        customDateTime(flightInFo?.origin?.etd),
        customDateTime(flightInFo?.destination?.eta),
      ],
    },
    {
      label:
        journeyStatusLargerOrEqualCustoms && a1Batch?.released_at
          ? intl.formatMessage({id: 'RELEASED'})
          : null,
      value: [journeyStatusLargerOrEqualCustoms && customDateTime(a1Batch?.released_at)],
    },
    {label: null, value: [customDateTime(a1Batch?.dropped_off_at)]},
    {
      children: delivery && deliveryLabel,
    },
  ]

  const dataRowsSecond = [
    {label: null, value: [{date: null, time: null}]},
    {
      label: !!pickupAddress?.short ? intl.formatMessage({id: 'PICK_UP_ADDRESS'}) : null,
      children: !!pickupAddress.short && pickupModal,
    },
    {
      label:
        flightInFo?.origin?.ata || flightInFo?.destination?.ata
          ? `${intl.formatMessage({id: 'ATD'})}/${intl.formatMessage({id: 'ATA'})}`
          : null,
      value: [
        customDateTime(flightInFo?.origin?.atd),
        customDateTime(flightInFo?.destination?.ata),
      ],
    },
    {
      label: holdModal ? intl.formatMessage({id: 'HOLD_REJECTED'}) : null,
      children: (
        <div className='px-2 fw-bold'>
          {holdModal && (
            <div
              className='bg-light-danger bg-hover-light-info p-2 rounded-pill text-decoration-underline cursor-pointer'
              onClick={() => setHasShow((prev) => ({...prev, holdModal: true}))}
            >
              {holdModal} {intl.formatMessage({id: 'ORDER'})}
            </div>
          )}
        </div>
      ),
    },
    {label: null, value: [{date: null, time: null}]},
    {label: null, value: [{date: null, time: null}]},
  ]

  return (
    <>
      {hasShow.holdModal && (
        <HoldOrderModal
          show={hasShow.holdModal}
          handleClose={() => {
            setHasShow((prev) => ({...prev, holdOrder: false}))
            setReloadTableDetail((prev) => !prev)
          }}
        />
      )}
      <div className='card mt-4 mt-xxl-0 ms-xxl-4 h-100'>
        <div className='card-header border-0 d-flex justify-content-between align-items-center fw-bolder'>
          <span className='fs-3'>{intl.formatMessage({id: 'JOURNEY_STATUS'})}</span>
        </div>
        <div className='card-body d-flex flex-column scroll-x pb-6'>
          <div className='d-flex text-center mb-6'>
            {Object.entries(omit(A1_CONSTANTS.OPTIONS_JOURNEY_STATUS, 'N_A')).map(
              ([key, status]) => (
                <div className='col-2 min-w-100px position-relative' key={key}>
                  <div className='w-100 border border-2 border-secondary position-absolute top-50 start-50 translate-middle' />
                  <KTSVG
                    path={`/media/gori/a1/${
                      status.value.includes(a1Batch?.journey_status)
                        ? 'checkboxChecked'
                        : 'checkboxUnchecked'
                    }.svg`}
                    style={{
                      color: status.value.includes(a1Batch?.journey_status) ? status.color : '',
                    }}
                    className='svg-icon-1 position-absolute top-50 start-50 translate-middle'
                  />
                </div>
              )
            )}
          </div>
          <div className='d-flex text-center fs-5 fs-xxl-7 fw-bolder mb-4'>
            {Object.entries(omit(A1_CONSTANTS.OPTIONS_JOURNEY_STATUS, 'N_A')).map(
              ([key, status]) => (
                <StatusRow label={intl.formatMessage({id: status.label})} key={key} />
              )
            )}
          </div>
          <div className='d-flex text-center fs-6 fs-xxl-8 fw-bolder mb-2'>
            {dataRowsFirst.map(({label}, idx) => (
              <StatusRow label={label} key={idx} />
            ))}
          </div>
          <div className='d-flex text-center fs-6 fs-xxl-8 fw-bolder mb-4'>
            {dataRowsFirst.map((data: {label?: any; value?: any; children?: any}, idx) => (
              <DataRow data={data} key={idx} />
            ))}
          </div>
          <div className='d-flex text-center fs-6 fs-xxl-8 fw-bolder mb-2'>
            {dataRowsSecond.map(({label}, idx) => (
              <StatusRow label={label} key={idx} />
            ))}
          </div>
          <div className='d-flex text-center fs-6 fs-xxl-8 fw-bolder'>
            {dataRowsSecond.map((data: {label?: any; value?: any; children?: any}, idx) => (
              <DataRow data={data} key={idx} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export {JourneyStatusCard}
