import {DateOrTimeView} from '@mui/x-date-pickers'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {every, isEmpty, omit, size} from 'lodash'
import moment from 'moment'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {ConfirmActionModal, KTSVG} from '../../../../../gori/components'
import {formatBytes} from '../../../../../gori/helpers'
import {
  useCancelToken,
  UsePermission,
  UseUploadFile,
  UseYupValidate,
} from '../../../../../gori/hooks'
import {useA1BatchesProvider, useDisabled} from '../../../../../gori/providers'
import {
  Button,
  InputDate,
  InputTextFormik,
  SelectFormik,
  UploadFile,
  ValidationErrorModal,
} from '../../../../../gori/widgets'
import {A1_CONSTANTS, A1BatchService, T86DocumentTable} from '../../../a1'

type Props = {
  show: boolean
  handleClose: () => void
  handleSuccess: () => void
}

const JourneyInformationModal: React.FC<Props> = ({show, handleClose, handleSuccess}) => {
  const intl = useIntl()
  const {id} = useParams()
  const {setPageDisabled} = useDisabled()
  const {isOrigin, isDestination} = UsePermission()
  const {stringYup, dateYup, numberYup} = UseYupValidate()
  const {isCancel, newCancelToken} = useCancelToken()
  const {a1Batch} = useA1BatchesProvider()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [editAdditionalCharge, setEditAdditionalCharge] = useState<{
    show: boolean
    type: 'add' | 'edit' | null
    id: number | null
  }>({
    show: false,
    type: null,
    id: null,
  })
  const [existsDocument, setExistsDocument] = useState<boolean>(false)
  const [loading, setLoading] = useState<{update: boolean; download: boolean}>({
    update: false,
    download: false,
  })
  const layoverCurrent = useRef(-1)
  const miscCurrent = useRef(0)
  const [showModal, setShowModal] = useState<{confirmAdditionalCharge: boolean}>({
    confirmAdditionalCharge: false,
  })
  const fileInputRef = React.useRef<HTMLInputElement | null>(null)
  const {setFile, handleDelete} = UseUploadFile({
    fileInput: [],
    multiple: false,
    supportedFormat: [A1_CONSTANTS.UPLOAD_CONFIG.SUPPORTED_FORMATS.CSV],
    maxRow: A1_CONSTANTS.UPLOAD_CONFIG.MAX_UPLOAD,
    onSubmit: (files) => {
      formik.setFieldValue('file', files[0] || null)
    },
  })

  const viewDate = useMemo(() => {
    return ['year', 'month', 'day', 'hours', 'minutes'] as DateOrTimeView[]
  }, [])

  const handleCloseModal = () => {
    handleClose()
  }

  const flights = useMemo(() => {
    return a1Batch?.journey_flight_infos?.reduce(
      (acc, flight) => ({
        ...acc,
        [flight.type]: flight,
      }),
      {}
    )
  }, [a1Batch?.journey_flight_infos])

  const layoverValidation = useMemo(() => {
    return Yup.object().shape({
      airport: stringYup(255, 'LAYOVER'),
      flight_number: stringYup(50, 'FLIGHT_NUMBER'),
      est_duration: numberYup.journey_info({
        name: 'DURATION',
        max: {value: 1000, label: '1,000'},
      }),
      [isDestination ? 'ata' : 'eta']: dateYup(
        isDestination ? 'ATA' : 'ETA',
        isDestination ? moment(flights?.origin?.etd).format() : moment().startOf('day').format()
      ),
      [isDestination ? 'atd' : 'etd']: dateYup(
        intl.formatMessage({id: isDestination ? 'ATD' : 'ETD'}),
        isDestination ? moment(flights?.origin?.etd).format() : moment().startOf('day').format()
      ),
    })
  }, [dateYup, flights?.origin?.etd, intl, isDestination, numberYup, stringYup])

  const miscValidation = useMemo(() => {
    return Yup.object().shape({
      title: stringYup(255, 'CHARGE_NAME'),
      amount: numberYup.journey_info({
        name: 'AMOUNT',
        required: false,
        min: {value: 0, label: '0'},
        max: {value: 1000000, label: '1,000,000'},
      }),
    })
  }, [numberYup, stringYup])

  const initValidateSchema = useMemo(() => {
    return {
      origin: Yup.object().shape({
        airport: stringYup(255, 'ORIGIN'),
        flight_number: stringYup(50, 'FLIGHT_NUMBER'),
        est_duration: numberYup.journey_info({
          name: 'DURATION',
          max: {value: 1000, label: '1,000'},
        }),
        [isDestination ? 'atd' : 'etd']: dateYup(
          intl.formatMessage({id: isDestination ? 'ATD' : 'ETD'}),
          isDestination ? moment(flights?.origin?.etd).format() : moment().startOf('day').format()
        ),
      }),
      destination: Yup.object().shape({
        airport: stringYup(50, 'DESTINATION'),
        [isDestination ? 'ata' : 'eta']: dateYup(
          isDestination ? 'ATA' : 'ETA',
          isDestination ? moment(flights?.origin?.etd).format() : moment().startOf('day').format()
        ),
      }),
      layover: Yup.object(),
      edit_misc: Yup.object(),
      invalid_date: Yup.string().when(['origin', 'layover', 'destination'], {
        is: (origin, layover, destination) => {
          const layovers = Object.values(layover).flatMap(({eta, etd, ata, atd}: any) =>
            isDestination ? [ata, atd] : [eta, etd]
          )
          const arrDate = isDestination
            ? [origin.atd, ...layovers, destination.ata].filter(Boolean)
            : [origin.etd, ...layovers, destination.eta].filter(Boolean)
          const arrDateValid = arrDate.filter((date) => moment(date)?.isValid())

          return !every(
            arrDateValid,
            (date, index, arr) => index === 0 || moment(date).isAfter(moment(arr[index - 1]))
          )
        },
        then: Yup.string()
          .nullable()
          .required(
            intl.formatMessage({
              id: isDestination
                ? 'THE_DATES_ORIGIN_ATD_LAYOVER_ATD_ATA_AND_DESTINATION_ATA_MUST_BE_IN_CHRONOLOGICAL_ORDER'
                : 'THE_DATES_ORIGIN_ETD_LAYOVER_ETD_ETA_AND_DESTINATION_ETA_MUST_BE_IN_CHRONOLOGICAL_ORDER',
            })
          ),
        otherwise: Yup.string().nullable(),
      }),
      mawb: stringYup(255, 'MAWB'),
      shipment_detail: Yup.object().shape({
        pallets: numberYup.journey_info({
          name: 'PALLETS',
          integer: true,
          max: {value: 10000, label: '10,000'},
        }),
        parcels: numberYup.journey_info({
          name: 'PARCELS',
          integer: true,
          max: {value: 10000, label: '10,000'},
        }),
        weight: numberYup.journey_info({
          name: 'KGS',
          min: {value: 0, label: '0'},
          max: {value: 1000000, label: '1,000,000'},
          moreThan: true,
        }),
      }),
      charges: Yup.object().shape(
        ['PICK_UP', 'CONSOLIDATION', 'FREIGHT', 'CLEARANCE', 'DECONSOLIDATION', 'TRANSFER'].reduce(
          (acc, name) => {
            acc[name.toLowerCase()] = numberYup.journey_info({
              name,
              required: false,
              min: {value: 0, label: '0'},
              max: {value: 1000000, label: '1,000,000'},
            })
            return acc
          },
          {} as Record<string, any>
        )
      ),
      t86_files: Yup.array().nullable(),
    }
  }, [dateYup, flights?.origin?.etd, intl, isDestination, numberYup, stringYup])

  const initialValues = useMemo(() => {
    return {
      origin: {
        id: flights?.origin?.id || null,
        airport: flights?.origin?.airport || null,
        flight_number: flights?.origin?.flight_number || null,
        etd: flights?.origin?.etd || null,
        atd: flights?.origin?.atd || flights?.origin?.etd,
        est_duration: flights?.origin?.est_duration || null,
      },
      destination: {
        id: flights?.destination?.id || null,
        airport: flights?.destination?.airport || null,
        flight_number: flights?.destination?.flight_number || null,
        eta: flights?.destination?.eta || null,
        ata: flights?.destination?.ata || flights?.destination?.eta,
      },
      layover: {},
      misc: {},
      edit_misc: {title: null, amount: null},
      invalid_date: null,
      mawb: a1Batch?.mawb || null,
      shipment_detail: {
        pallets: a1Batch?.pallets || null,
        parcels: a1Batch?.parcels || null,
        weight: a1Batch?.weight || null,
      },
      charges: {
        pick_up: a1Batch?.origin_pick_up || null,
        consolidation: a1Batch?.origin_consolidation || null,
        freight: a1Batch?.origin_freight || null,
        clearance: a1Batch?.destination_clearance || null,
        deconsolidation: a1Batch?.destination_deconsolidation || null,
        transfer: a1Batch?.destination_transfer || null,
      },
      file: null as File | null,
      t86_files: [],
    }
  }, [a1Batch, flights])

  const [validateSchema, setValidateSchema] = useState<any>(initValidateSchema)

  const handleUpdateJourneyInfo = useCallback(
    async ({values, hasPass = false}) => {
      if (!hasPass && editAdditionalCharge.show) {
        return setShowModal((prev) => ({...prev, confirmAdditionalCharge: true}))
      }

      try {
        setLoading((prev) => ({...prev, update: true}))
        const config = {cancelToken: newCancelToken()}
        const flights = [values.origin, ...Object.values(values.layover), values.destination]
        const _payload = {
          ...omit(
            values,
            'origin',
            'destination',
            'invalid_date',
            'layover',
            'file',
            'misc',
            'edit_misc'
          ),
          flights,
          charges: {
            ...values.charges,
            [`${isOrigin ? 'origin' : 'destination'}_misc`]: Object.values(values.misc),
          },
        }
        const payload = new FormData()
        payload.append('data', JSON.stringify(_payload))
        if (values?.file) {
          payload.append('file', values.file)
        }
        if (values?.t86_files) {
          const t86_files = values?.t86_files
          t86_files.map((file) => {
            payload.append('t86_files[]', file)
            return file
          })
        }
        const res: any = await A1BatchService.updateJourneyInfo(a1Batch.id, payload, config)
        if (res) {
          toast.success(res.message)
          handleSuccess()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, update: false}))
      }
    },
    [a1Batch.id, editAdditionalCharge.show, handleSuccess, isCancel, isOrigin, newCancelToken]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape(validateSchema),
    onSubmit: (values) => {
      handleUpdateJourneyInfo({values})
    },
  })

  const handleAddLayover = () => {
    layoverCurrent.current++
    formik.setValues({
      ...formik.values,
      layover: {
        ...formik.values?.layover,
        [layoverCurrent.current]: {
          airport: null,
          flight_number: null,
          est_duration: null,
          eta: null,
          etd: null,
        },
      },
    })

    setValidateSchema((prev) => ({
      ...prev,
      layover: Yup.object().shape({
        ...prev.layover.fields,
        [layoverCurrent.current]: layoverValidation,
      }),
    }))
  }

  const handleDeleteLayover = (key: string) => {
    const layover = formik.values?.layover
    delete layover[key]
    formik.setValues({
      ...formik.values,
      layover: layover,
    })

    setValidateSchema((prev) => {
      delete prev.layover.fields[key]
      return prev
    })
  }

  const handleDownloadTemplate = async () => {
    try {
      setLoading((prev) => ({...prev, download: true}))
      const response: any = await A1BatchService.downloadOrdersA1Batch(a1Batch.id, {
        cancelToken: newCancelToken(),
      })
      if (response) {
        const blob = new Blob([response.content], {type: response.type})
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = response.file_name
        link.click()
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setLoading((prev) => ({...prev, download: false}))
    }
  }

  useEffect(() => {
    const handleInitValueLayover = async () => {
      let layoverValues = {}
      let layoverSchema = {}

      a1Batch.journey_flight_infos.forEach((flight) => {
        if (flight.type === 'layover') {
          layoverCurrent.current++
          layoverValues[layoverCurrent.current] = {
            id: flight.id,
            airport: flight.airport,
            flight_number: flight.flight_number,
            eta: flight.eta,
            etd: flight.etd,
            ata: flight.ata ? flight.ata : flight.eta,
            atd: flight.atd ? flight.atd : flight.etd,
            est_duration: flight.est_duration,
          }
          layoverSchema[layoverCurrent.current] = layoverValidation
        }
      })

      await formik.setFieldValue('layover', {
        ...formik.values?.layover,
        ...layoverValues,
      })

      setValidateSchema((prev) => ({
        ...prev,
        layover: Yup.object().shape({
          ...prev.layover.fields,
          ...layoverSchema,
        }),
      }))
      await formik.validateForm()
    }

    const handleInitValueMisc = async () => {
      let miscValues = {}
      a1Batch?.[isOrigin ? 'origin_misc' : 'destination_misc']?.forEach((misc) => {
        miscValues[miscCurrent.current] = {
          title: misc.title,
          amount: misc.amount,
        }
        miscCurrent.current++
      })

      await formik.setFieldValue('misc', miscValues)
    }

    if (!isEmpty(a1Batch?.journey_flight_infos)) {
      handleInitValueLayover()
    }

    if (!isEmpty(a1Batch?.origin_misc) || !isEmpty(a1Batch?.destination_misc)) {
      handleInitValueMisc()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.initialValues])

  const checkExistDocument = useCallback(async () => {
    try {
      setPageDisabled(true)
      const config = {
        cancelToken: newCancelToken(),
      }
      const response: any = await A1BatchService.checkExistsDocument(id, config)
      if (response) {
        setExistsDocument(response.exists ?? false)
        if (!response.exists) {
          setValidateSchema((prev) => ({
            ...prev,
            t86_files: Yup.array().min(
              1,
              intl.formatMessage(
                {id: 'INPUT_IS_REQUIRED'},
                {input: intl.formatMessage({id: 'T86_DOCUMENTATIONS'})}
              )
            ),
          }))
        }
      }
    } catch (error: any) {
      if (isCancel(error)) return
    } finally {
      setPageDisabled(false)
    }
  }, [id, isCancel, newCancelToken, setPageDisabled, setValidateSchema, intl])

  useEffect(() => {
    if (isOrigin) {
      checkExistDocument()
    }
  }, [checkExistDocument, isOrigin])

  const handleAdditionalCharge = ({type, id}) => {
    setEditAdditionalCharge({show: true, type: type, id: id})
    setValidateSchema((prev) => ({
      ...prev,
      edit_misc: miscValidation,
    }))
  }

  const handleCancelEditAdditionalCharge = useCallback(() => {
    formik.setFieldValue('edit_misc', {title: null, amount: null})
    formik.setTouched({...formik.touched, edit_misc: {title: false, amount: false}})
    setValidateSchema((prev) => omit(prev, 'edit_misc'))
    setEditAdditionalCharge({show: false, type: null, id: null})
  }, [formik])

  const handleSaveEditAdditionalCharge = useCallback(() => {
    if (!formik.values?.edit_misc.title || formik.errors.edit_misc) {
      return formik.setTouched({...formik.touched, edit_misc: {title: true, amount: true}})
    }

    miscCurrent.current = Number(miscCurrent.current) + 1
    formik.setFieldValue(`misc.${editAdditionalCharge?.id}`, formik.values?.edit_misc)

    handleCancelEditAdditionalCharge()
  }, [editAdditionalCharge?.id, formik, handleCancelEditAdditionalCharge])

  const handleDeleteEditAdditionalCharge = () => {
    const misc = formik.values?.misc
    if (editAdditionalCharge?.id !== null && misc[editAdditionalCharge?.id]) {
      delete misc?.[editAdditionalCharge?.id]
      formik.setValues((prev) => ({
        ...prev,
        misc: misc,
      }))
    }

    handleCancelEditAdditionalCharge()
  }

  const handleCloseConfirmAdditionalCharge = useCallback(() => {
    setShowModal((prev) => ({...prev, confirmAdditionalCharge: false}))
  }, [])

  const handleConfirmSaveAdditionalCharge = useCallback(() => {
    if (!editAdditionalCharge.id?.toString()) return

    const values = {
      ...formik.values,
      misc: {...formik.values.misc, [editAdditionalCharge?.id]: formik.values?.edit_misc},
    }
    handleCloseConfirmAdditionalCharge()
    handleUpdateJourneyInfo({values, hasPass: true})
  }, [
    editAdditionalCharge.id,
    formik.values,
    handleCloseConfirmAdditionalCharge,
    handleUpdateJourneyInfo,
  ])

  const handleConfirmSkipAdditionalCharge = useCallback(() => {
    handleCloseConfirmAdditionalCharge()
    handleUpdateJourneyInfo({values: formik.values, hasPass: true})
  }, [formik.values, handleCloseConfirmAdditionalCharge, handleUpdateJourneyInfo])

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      {showModal.confirmAdditionalCharge && (
        <ConfirmActionModal
          show={showModal.confirmAdditionalCharge}
          classNameDialog='mw-500px'
          title={intl.formatMessage({id: 'ADDITIONAL_CHARGE_CONFIRMATION'})}
          message={
            <span className='fw-bold text-center'>
              <p>{intl.formatMessage({id: 'THE_ADDITIONAL_CHARGE_HAS_NOT_BEEN_SAVED'})}</p>
              <p>{intl.formatMessage({id: 'WOULD_YOU_LIKE_TO_SAVE_IT_BEFORE_PROCEEDING'})}</p>
            </span>
          }
          messagePrimary={intl.formatMessage({id: 'YES'})}
          messageSecondary={intl.formatMessage({id: 'NO'})}
          handlePrimaryAction={handleConfirmSaveAdditionalCharge}
          handleSecondaryAction={handleConfirmSkipAdditionalCharge}
          handleClose={handleCloseConfirmAdditionalCharge}
        />
      )}
      <Modal
        id='gori_modal_journey_information_modal'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1250px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'JOURNEY_INFORMATION'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <Container>
              <div className='border-bottom'>
                <h3 className='required'>{intl.formatMessage({id: 'FLIGHT_INFORMATION'})}</h3>
                <div className='mt-4'>
                  <div className='d-flex'>
                    <div className='flex-fill d-flex flex-column flex-md-row'>
                      <div className='col-12 col-md-4'>
                        <div className='d-flex flex-fill px-6'>
                          <SelectFormik
                            className='col-7'
                            labelClassName='col-5 col-form-label fs-5 fw-bold'
                            label={intl.formatMessage({id: 'ORIGIN'})}
                            placeholder={intl.formatMessage({id: 'ORIGIN'})}
                            options={A1_CONSTANTS.OPTIONS_ORIGIN}
                            formik={formik}
                            name='origin.airport'
                            emptyDefault={false}
                            disabled={isDestination}
                          />
                        </div>
                      </div>
                      <div className='col-12 col-md-4'>
                        <div className='d-flex flex-fill px-6 mt-4 mt-md-0'>
                          <InputTextFormik
                            className='col-7 col-md-7'
                            label={intl.formatMessage({id: 'FLIGHT_NUMBER'})}
                            labelClassName='col-5 col-md-5 col-form-label fs-5 fw-bold'
                            formik={formik}
                            name='origin.flight_number'
                            disabled={isDestination}
                          />
                        </div>
                      </div>
                      <div className='col-12 col-md-4'>
                        <div className='d-flex flex-fill px-6 mt-4 mt-md-0'>
                          <InputDate
                            className={'col-8'}
                            label={intl.formatMessage({id: isDestination ? 'ATD' : 'ETD'})}
                            labelClassName='col-4 col-form-label fs-5 fw-bold'
                            name={isDestination ? 'origin.atd' : 'origin.etd'}
                            formik={formik}
                            min={isDestination ? moment(flights?.origin?.etd) : moment()}
                            views={viewDate}
                          />
                        </div>
                        <div className='d-flex flex-fill px-6 mt-4'>
                          <InputTextFormik
                            type='number'
                            className='col-8'
                            label={intl.formatMessage({id: 'DURATION_HOUR'})}
                            placeholder={intl.formatMessage({id: 'HOUR'})}
                            labelClassName='col-4 col-form-label fs-5 fw-bold'
                            formik={formik}
                            name='origin.est_duration'
                            disabled={isDestination}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='w-50px' />
                  </div>
                  {formik.values?.layover &&
                    Object.entries(formik.values?.layover).map(([key, value], idx) => {
                      return (
                        <div className='d-flex mt-4' key={idx}>
                          <div className='flex-fill d-flex flex-column flex-md-row align-items-center'>
                            <div className='col-12 col-md-4'>
                              <div className='px-6'>
                                <div className='d-flex flex-fill'>
                                  <SelectFormik
                                    className='col-7'
                                    labelClassName='col-5 col-form-label fs-5 fw-bold'
                                    label={`${intl.formatMessage({id: 'LAYOVER'})} ${idx + 1}`}
                                    placeholder={`${intl.formatMessage({id: 'LAYOVER'})} ${
                                      idx + 1
                                    }`}
                                    options={A1_CONSTANTS.OPTIONS_ORIGIN}
                                    formik={formik}
                                    name={`layover.${key}.airport`}
                                    emptyDefault={false}
                                    disabled={isDestination}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='col-12 col-md-4'>
                              <div className='d-flex flex-fill px-6 mt-4 mt-md-0'>
                                <InputTextFormik
                                  className='col-7 col-md-7'
                                  label={intl.formatMessage({id: 'FLIGHT_NUMBER'})}
                                  labelClassName='col-5 col-md-5 col-form-label fs-5 fw-bold'
                                  formik={formik}
                                  name={`layover.${key}.flight_number`}
                                  disabled={isDestination}
                                />
                              </div>
                            </div>
                            <div className='col-12 col-md-4'>
                              <div className='px-6'>
                                <div className='d-flex mt-4 mt-md-0'>
                                  <InputDate
                                    className={'col-8'}
                                    label={isDestination ? 'ATA' : 'ETA'}
                                    labelClassName='col-4 col-form-label fs-5 fw-bold'
                                    name={
                                      isDestination ? `layover.${key}.ata` : `layover.${key}.eta`
                                    }
                                    formik={formik}
                                    min={isDestination ? moment(flights?.origin?.etd) : moment()}
                                    views={viewDate}
                                  />
                                </div>
                                <div className='d-flex mt-4'>
                                  <InputDate
                                    className={'col-8'}
                                    label={intl.formatMessage({
                                      id: isDestination ? 'ATD' : 'ETD',
                                    })}
                                    labelClassName='col-4 col-form-label fs-5 fw-bold'
                                    name={
                                      isDestination ? `layover.${key}.atd` : `layover.${key}.etd`
                                    }
                                    formik={formik}
                                    min={isDestination ? moment(flights?.origin?.etd) : moment()}
                                    views={viewDate}
                                  />
                                </div>
                                <div className='d-flex mt-4'>
                                  <InputTextFormik
                                    type='number'
                                    className='col-8'
                                    label={intl.formatMessage({id: 'DURATION_HOUR'})}
                                    placeholder={intl.formatMessage({id: 'HOUR'})}
                                    labelClassName='col-4 col-form-label fs-5 fw-bold'
                                    formik={formik}
                                    name={`layover.${key}.est_duration`}
                                    disabled={isDestination}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {isOrigin && (
                            <div className='d-flex align-items-center justify-content-center w-50px'>
                              <span
                                onClick={() => handleDeleteLayover(key)}
                                className='cursor-pointer'
                              >
                                <KTSVG
                                  path='/media/gori/common/empty.svg'
                                  className='svg-icon-1 text-gray-700 text-hover-danger'
                                  small={false}
                                />
                              </span>
                            </div>
                          )}
                          {isDestination && <div className='w-50px' />}
                        </div>
                      )
                    })}
                  <div className='d-flex mt-4'>
                    <div className='flex-fill d-flex flex-column flex-md-row'>
                      <div className='col-12 col-md-4'>
                        <div className='d-flex flex-fill px-6'>
                          <SelectFormik
                            className='col-7'
                            labelClassName='col-5 col-form-label fs-5 fw-bold'
                            label={intl.formatMessage({id: 'DESTINATION'})}
                            placeholder={intl.formatMessage({id: 'DESTINATION'})}
                            options={A1_CONSTANTS.OPTIONS_DESTINATION}
                            formik={formik}
                            name='destination.airport'
                            emptyDefault={false}
                            disabled={isDestination}
                          />
                        </div>
                      </div>
                      <div className='col-12 col-md-4' />
                      <div className='col-12 col-md-4'>
                        <div className='d-flex flex-fill px-6 mt-4 mt-md-0'>
                          <InputDate
                            className={'col-8'}
                            label={isDestination ? 'ATA' : 'ETA'}
                            labelClassName='col-4 col-form-label fs-5 fw-bold'
                            name={isDestination ? 'destination.ata' : 'destination.eta'}
                            formik={formik}
                            min={isDestination ? moment(flights?.origin?.etd) : moment()}
                            views={viewDate}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='w-50px' />
                  </div>
                </div>
                {formik.errors.invalid_date && (
                  <div className='d-flex flex-row-reverse'>
                    <span className='col-12 col-md-4 text-center my-4'>
                      <span className='text-danger'>{formik.errors.invalid_date}</span>
                    </span>
                  </div>
                )}
                {isOrigin && (
                  <div
                    className={clsx(
                      'cursor-pointer fw-bold text-gray-700 my-4 py-4 px-2 bg-light border-secondary border-top border-bottom',
                      {
                        'cursor-no-drop opacity-75':
                          size(formik.values?.layover) >= A1_CONSTANTS.MAX_LAYOVER,
                        'bg-hover-light-primary text-hover-primary':
                          size(formik.values?.layover) < A1_CONSTANTS.MAX_LAYOVER,
                      }
                    )}
                    onClick={() =>
                      size(formik.values?.layover) < A1_CONSTANTS.MAX_LAYOVER && handleAddLayover()
                    }
                  >
                    + {intl.formatMessage({id: 'ADD_LAYOVER'})}
                  </div>
                )}
                <div className='d-flex mt-4'>
                  <div className='flex-fill'>
                    <div className='col-12 col-md-4 d-flex mb-4 px-6'>
                      <InputTextFormik
                        className='col-7'
                        label={'MAWB'}
                        labelClassName='col-5 col-form-label fs-5 fw-bold'
                        formik={formik}
                        name='mawb'
                        disabled={isDestination}
                      />
                    </div>
                  </div>
                  <div className='w-50px'></div>
                </div>
              </div>
              {isOrigin && (
                <>
                  <div className='border-bottom mt-10'>
                    <h3 className='required'>{intl.formatMessage({id: 'SHIPMENT_DETAIL'})}</h3>
                    <div className='d-flex flex-column flex-md-row my-8'>
                      <div className='d-flex col-12 col-md-4 mt-4 mt-md-0'>
                        <InputTextFormik
                          className='col-7'
                          label={`# ${intl.formatMessage({id: 'OF_PALLETS'})}`}
                          labelClassName='col-5 col-form-label fs-5 fw-bold'
                          formik={formik}
                          type='number'
                          isInteger
                          name='shipment_detail.pallets'
                        />
                      </div>
                      <div className='d-flex col-12 col-md-4 mt-4 mt-md-0'>
                        <InputTextFormik
                          className='col-7'
                          label={`# ${intl.formatMessage({id: 'OF_PARCELS'})}`}
                          labelClassName='col-5 col-form-label fs-5 fw-bold ps-0 ps-md-3'
                          formik={formik}
                          type='number'
                          isInteger
                          name='shipment_detail.parcels'
                        />
                      </div>
                      <div className='d-flex col-12 col-md-4 mt-4 mt-md-0'>
                        <InputTextFormik
                          className='col-7'
                          label={intl.formatMessage({id: 'KGS'})}
                          labelClassName='col-5 col-form-label fs-5 fw-bold ps-0 ps-md-3'
                          formik={formik}
                          type='number'
                          name='shipment_detail.weight'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='mt-10'>
                    <h3>{intl.formatMessage({id: 'CHARGES'})}</h3>
                    <div className='d-flex flex-column flex-md-row mt-8'>
                      <div className='d-flex col-12 col-md-4 mt-4 mt-md-0'>
                        <InputTextFormik
                          className='col-7'
                          type='number'
                          label={intl.formatMessage({id: 'PICK_UP'})}
                          labelClassName='col-5 col-form-label fs-5 fw-bold'
                          formik={formik}
                          name='charges.pick_up'
                          configAppend={{
                            name: 'USD',
                            position: 'right',
                            classInput: 'pe-25',
                          }}
                        />
                      </div>
                      <div className='d-flex col-12 col-md-4 mt-4 mt-md-0'>
                        <InputTextFormik
                          className='col-7'
                          type='number'
                          label={intl.formatMessage({id: 'CONSOLIDATION'})}
                          labelClassName='col-5 col-form-label fs-5 fw-bold ps-0 ps-md-3'
                          formik={formik}
                          name='charges.consolidation'
                          configAppend={{
                            name: 'USD',
                            position: 'right',
                            classInput: 'pe-25',
                          }}
                        />
                      </div>
                      <div className='d-flex col-12 col-md-4 mt-4 mt-md-0'>
                        <InputTextFormik
                          className='col-7'
                          type='number'
                          label={intl.formatMessage({id: 'FREIGHT'})}
                          labelClassName='col-5 col-form-label fs-5 fw-bold ps-0 ps-md-3'
                          formik={formik}
                          name='charges.freight'
                          configAppend={{
                            name: 'USD',
                            position: 'right',
                            classInput: 'pe-25',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {isDestination && (
                <div className='mt-10'>
                  <h3>{intl.formatMessage({id: 'CHARGES'})}</h3>
                  <div className='d-flex flex-column flex-md-row mt-8'>
                    <div className='d-flex col-12 col-md-4 mt-4 mt-md-0'>
                      <InputTextFormik
                        className='col-7'
                        type='number'
                        label={intl.formatMessage({id: 'CLEARANCE'})}
                        labelClassName='col-5 col-form-label fs-5 fw-bold'
                        formik={formik}
                        name='charges.clearance'
                        configAppend={{
                          name: 'USD',
                          position: 'right',
                          classInput: 'pe-25',
                        }}
                      />
                    </div>
                    <div className='d-flex col-12 col-md-4 mt-4 mt-md-0'>
                      <InputTextFormik
                        className='col-7'
                        type='number'
                        label={intl.formatMessage({id: 'DECONSOLIDATION'})}
                        labelClassName='col-5 col-form-label fs-5 fw-bold ps-0 ps-md-3'
                        formik={formik}
                        name='charges.deconsolidation'
                        configAppend={{
                          name: 'USD',
                          position: 'right',
                          classInput: 'pe-25',
                        }}
                      />
                    </div>
                    <div className='d-flex col-12 col-md-4 mt-4 mt-md-0'>
                      <InputTextFormik
                        className='col-7'
                        type='number'
                        label={intl.formatMessage({id: 'TRANSFER'})}
                        labelClassName='col-5 col-form-label fs-5 fw-bold ps-0 ps-md-3'
                        formik={formik}
                        name='charges.transfer'
                        configAppend={{
                          name: 'USD',
                          position: 'right',
                          classInput: 'pe-25',
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className='border-bottom'>
                <div className='d-flex flex-column flex-md-row flex-wrap'>
                  {formik.values?.misc &&
                    Object.entries(formik.values?.misc).map(([key, value]: [string, any], idx) => {
                      return (
                        <div
                          className='d-flex col-12 col-md-4 mt-4 cursor-pointer'
                          key={idx}
                          onClick={() => {
                            formik.setFieldValue('edit_misc', value)
                            handleAdditionalCharge({type: 'edit', id: Number(key)})
                          }}
                        >
                          <InputTextFormik
                            className='col-7 pe-none'
                            type='number'
                            label={value.title}
                            labelClassName={clsx(
                              'col-5 col-form-label fs-5 fw-bold text-truncate cursor-pointer',
                              {'px-0 px-md-3': idx % 3 !== 0}
                            )}
                            formik={formik}
                            name={`misc.${key}.amount`}
                            configAppend={{
                              name: 'USD',
                              position: 'right',
                              classInput: 'pe-25',
                            }}
                          />
                        </div>
                      )
                    })}
                </div>
                {editAdditionalCharge?.show && editAdditionalCharge?.id !== null ? (
                  <div className='bg-light border-bottom my-4'>
                    <div className='d-flex justify-content-between align-items-center bg-secondary py-4 px-2 '>
                      <h3 className='m-0 mw-500px text-truncate'>
                        {editAdditionalCharge?.type === 'add'
                          ? intl.formatMessage({id: 'ADD_ADDITIONAL_CHARGE'})
                          : formik.values?.misc?.[editAdditionalCharge?.id]?.title}
                      </h3>
                      <div className='d-flex align-items-center gap-4'>
                        <span className='cursor-pointer' onClick={handleCancelEditAdditionalCharge}>
                          <KTSVG
                            path='/media/gori/common/delete.svg'
                            className='svg-icon-3 text-danger'
                          />
                        </span>
                        <span className='cursor-pointer' onClick={handleSaveEditAdditionalCharge}>
                          <KTSVG path='/media/gori/common/valid.svg' className='svg-icon-1' />
                        </span>
                        {editAdditionalCharge?.type === 'edit' && (
                          <span
                            className='cursor-pointer'
                            onClick={handleDeleteEditAdditionalCharge}
                          >
                            <KTSVG path='/media/gori/common/empty.svg' className='svg-icon-1' />
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='d-flex flex-column flex-md-row justify-content-between p-4 gap-6'>
                      <div className='d-flex col-12 col-md-5'>
                        <InputTextFormik
                          className='col-8'
                          label={intl.formatMessage({id: 'CHARGE_NAME'})}
                          labelClassName='col-4 col-form-label fs-5 fw-bold'
                          formik={formik}
                          name='edit_misc.title'
                        />
                      </div>
                      <div className='d-flex col-12 col-md-5'>
                        <InputTextFormik
                          className='col-8'
                          type='number'
                          label={intl.formatMessage({id: 'AMOUNT'})}
                          labelClassName='col-4 col-form-label fs-5 fw-bold'
                          formik={formik}
                          name='edit_misc.amount'
                          configAppend={{
                            name: 'USD',
                            position: 'right',
                            classInput: 'pe-25',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={clsx(
                      'cursor-pointer fw-bold text-gray-700 my-4 py-4 px-2 bg-light border-secondary border-top border-bottom',
                      {
                        'cursor-no-drop opacity-75':
                          size(formik.values?.misc) >= A1_CONSTANTS.MAX_ADDITIONAL_CHARGE,
                        'bg-hover-light-primary text-hover-primary':
                          size(formik.values?.misc) < A1_CONSTANTS.MAX_ADDITIONAL_CHARGE,
                      }
                    )}
                    onClick={() => {
                      if (size(formik.values?.misc) < A1_CONSTANTS.MAX_ADDITIONAL_CHARGE) {
                        handleAdditionalCharge({type: 'add', id: miscCurrent.current})
                      }
                    }}
                  >
                    + {intl.formatMessage({id: 'ADD_ADDITIONAL_CHARGE'})}
                  </div>
                )}
              </div>

              {isOrigin && (
                <>
                  <div className='mt-10'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className='d-flex gap-8'>
                        <h3 className='m-0'>{intl.formatMessage({id: 'UPDATE_CONTAINER_ID'})}</h3>
                        <span
                          className='text-gray-700 text-hover-dark text-decoration-underline cursor-pointer'
                          onClick={handleDownloadTemplate}
                        >
                          {intl.formatMessage({id: 'DOWNLOAD_TEMPLATE'})}
                        </span>
                        <span
                          className='text-teal text-decoration-underline cursor-pointer'
                          onClick={() => fileInputRef.current?.click()}
                        >
                          {intl.formatMessage({id: 'UPLOAD_FILE'})}
                        </span>
                        <input
                          type='file'
                          ref={fileInputRef}
                          onChange={setFile}
                          className='d-none'
                          accept={A1_CONSTANTS.UPLOAD_CONFIG.SUPPORTED_FORMATS.CSV.value}
                        />
                      </div>
                      {formik.values?.file && (
                        <div className='d-flex align-items-center mt-2 col-6'>
                          <div className='flex-fill d-flex justify-content-between bg-light-primary p-3 rounded-2 fw-bold w-75'>
                            <span className='text-truncate'>{formik.values?.file?.name}</span>
                            <span className='text-nowrap'>
                              {formatBytes(formik.values?.file?.size)}
                            </span>
                          </div>
                          <div
                            className='text-hover-danger cursor-pointer ms-4'
                            onClick={() => handleDelete(0)}
                          >
                            <KTSVG path='/media/gori/common/empty.svg' small={false} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='mt-10'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <h3 className={clsx({required: isOrigin && !existsDocument})}>
                        {intl.formatMessage({id: 'T86_DOCUMENTATIONS'})}
                      </h3>
                    </div>
                    <UploadFile
                      className='bg-light-primary border-primary border-2 mt-5'
                      onSubmit={(e) => formik.setFieldValue('t86_files', e.files)}
                      supportedFormat={Object.values(A1_CONSTANTS.UPLOAD_CONFIG.SUPPORTED_FORMATS)}
                      size={{label: '8MB', value: A1_CONSTANTS.UPLOAD_CONFIG.SIZE}}
                      multiple={true}
                    />
                    {formik.errors.t86_files && formik.touched.t86_files && (
                      <div className='text-danger mt-2'>{formik.errors.t86_files}</div>
                    )}
                  </div>
                  {existsDocument && <T86DocumentTable />}
                </>
              )}
              {isDestination && (
                <div className='mt-10'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h3>{intl.formatMessage({id: 'UPLOAD_FILE'})}</h3>
                    <Button
                      event={handleDownloadTemplate}
                      className='btn btn-sm btn-secondary fw-bolder'
                      label={intl.formatMessage({id: 'EXPORT_AS_CSV'})}
                      children={<KTSVG path='/media/gori/a1/fileDownload.svg' />}
                      loading={loading.download}
                    />
                  </div>
                  <UploadFile
                    className='bg-light-primary border-primary border-2 mt-5'
                    onSubmit={(e) => formik.setFieldValue('file', e.files?.[0] || null)}
                    supportedFormat={[A1_CONSTANTS.UPLOAD_CONFIG.SUPPORTED_FORMATS.CSV]}
                    maxRow={A1_CONSTANTS.UPLOAD_CONFIG.MAX_UPLOAD}
                    size={{label: '8MB', value: A1_CONSTANTS.UPLOAD_CONFIG.SIZE}}
                  />
                </div>
              )}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'UPDATE'})}
                disabled={loading.update}
                event={formik.handleSubmit}
                loading={loading.update}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {JourneyInformationModal}
