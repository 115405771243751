import clsx from 'clsx'
import {compact, find, isEmpty} from 'lodash'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Modal, OverlayTrigger, Popover} from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {KTSVG, TableSkeleton} from '../../../../../gori/components'
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  FORMAT_DATE,
  OPTION_COUNTRIES,
  TABLE_KEY_COLUMN,
} from '../../../../../gori/constants'
import {convertUserTimeZone} from '../../../../../gori/helpers'
import {useCancelToken, UsePermission} from '../../../../../gori/hooks'
import {
  useA1BatchesProvider,
  useAuth,
  useDisabled,
  useStatesGlobal,
} from '../../../../../gori/providers'
import {
  Button,
  SelectFormik,
  Table,
  TableBody,
  TableTabs,
  TableWrapper,
  ValidationErrorModal,
} from '../../../../../gori/widgets'
import {A1_CONSTANTS, A1BatchService, A1Helpers, MassUpdateModal, ReasonModal} from '../../../a1'
import {UpdateStatus} from './UpdateStatus'
import {StatusViewOnly} from './StatusViewOnly'

type Props = {
  show: boolean
  handleClose: () => void
}

const HoldOrderModal: React.FC<Props> = ({show, handleClose}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {a1Batch} = useA1BatchesProvider()
  const {isOrigin, isDestination} = UsePermission()
  const {setPageDisabled} = useDisabled()
  const [searchParams, setSearchParams] = useSearchParams()
  const {newCancelToken, isCancel} = useCancelToken()
  const {statesGlobal} = useStatesGlobal()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [tableData, setTableData] = useState<any>([])
  const [tableDataAll, setTableDataAll] = useState<any>([])
  const [statistic, setStatistic] = useState<any>([])
  const [loading, setLoading] = useState<{first: boolean; getOrder: boolean; release: boolean}>({
    first: true,
    getOrder: false,
    release: false,
  })
  const pageCurrent = useRef(Number(DEFAULT_PAGE))
  const [pagination, setPagination] = useState<{
    page: number
    page_size: number
    total: number
    total_pages: number
  }>({
    page: 0,
    page_size: 0,
    total: 0,
    total_pages: Number(DEFAULT_PAGE),
  })
  const [hasLoadMore, setHasLoadMore] = useState<boolean>(false)
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [selectedList, setSelectedList] = useState<any>([])
  const [selectedMaster, setSelectedMaster] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<{massUpdate: boolean; reason: boolean}>({
    massUpdate: false,
    reason: false,
  })
  const [updatingStatus, setUpdatingStatus] = useState<{[key: string]: boolean}>({})
  const [dataModal, setDataModal] = useState<{
    massUpdate: {
      selectAll: boolean
      selectedList: any
      typeMassUpdate: string
      releaseStatus?: string
      rowId?: number
    } | null
    reasonData: {type: string; reason: string; first_release_status: string} | null
  }>({
    massUpdate: null,
    reasonData: null,
  })
  const [showPopover, setShowPopover] = useState<Record<string, boolean>>({})
  const tabName = useMemo(() => searchParams.get('release_status') || 'hold', [searchParams]) as
    | 'hold'
    | 'rejected'

  const handleCloseModal = () => {
    handleClose()
    if (searchParams.get('release_status')) {
      searchParams.delete('release_status')
      setSearchParams(searchParams)
    }
  }

  const showMassUpdate = useMemo(() => {
    return A1Helpers.checkJourneyStatus({
      statusCurrent: a1Batch?.journey_status,
      statusCheck: A1_CONSTANTS.OPTIONS_JOURNEY_STATUS.CUSTOMS.value[0],
      comparisonType: 'less_than_or_equal',
    })
  }, [a1Batch?.journey_status])

  const configParams = useMemo(
    () => ({
      is_modal: true,
      batch_id: a1Batch?.id,
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      first_held: 1,
      release_status: tabName,
    }),
    [a1Batch?.id, tabName]
  )

  const setTable = useCallback(async () => {
    try {
      setLoading((prev) => ({...prev, first: true}))
      const config = {
        params: configParams,
        cancelToken: newCancelToken(),
      }
      const response: any = await A1BatchService.getA1BatchOrders(config)
      if (response) {
        const newOrder = Array.from(response.orders, (order: any) => {
          return {
            ...order,
            canCheck: true,
          }
        })
        setTableData(newOrder)
        setTableDataAll(response.all)
        setPagination(response.pagination)
        setStatistic(response.statistics)
        setHasLoadMore(response.pagination.total_pages > 1)
      }
    } catch (error: any) {
      if (isCancel(error)) return
    } finally {
      setLoading((prev) => ({...prev, first: false}))
      setPageDisabled(false)
    }
  }, [configParams, isCancel, newCancelToken, setPageDisabled])

  const fetchMore = async () => {
    if (pageCurrent.current < pagination?.total_pages) {
      pageCurrent.current++

      try {
        setLoading((prev) => ({...prev, getOrder: true}))
        const config = {
          params: {...configParams, page: pageCurrent.current},
          cancelToken: newCancelToken(),
        }
        const response: any = await A1BatchService.getA1BatchOrders(config)
        if (response) {
          let newOrder = Array.from(response.orders, (order: any) => {
            return {
              ...order,
              canCheck: true,
            }
          })
          setTableData((prev) => [...prev, ...newOrder])
        }
      } catch (error: any) {
        if (isCancel(error)) return
      } finally {
        setLoading((prev) => ({...prev, getOrder: false}))
      }
    } else {
      setHasLoadMore(false)
    }
  }

  useEffect(() => {
    const statusObj = {}

    tableData.forEach((row) => {
      statusObj[row.id] = false
    })
    setUpdatingStatus(statusObj)
  }, [tableData])

  useEffect(() => {
    setTable()
  }, [setTable])

  const toolTipModal = useCallback(
    ({
      message,
      className = '',
      messageTooltip = '',
      classNameTooltip = '',
      placement = 'bottom',
    }) => {
      return (
        <OverlayTrigger
          placement={placement}
          show={showPopover.id}
          overlay={
            messageTooltip ? (
              <Popover
                className='mw-350px p-5'
                onMouseEnter={() => setShowPopover((prev) => ({...prev, tooltip: true}))}
                onMouseLeave={() => setShowPopover((prev) => ({...prev, tooltip: false}))}
              >
                <div className={clsx('fw-bold text-truncate-2', classNameTooltip)}>
                  {messageTooltip}
                </div>
              </Popover>
            ) : (
              <> </>
            )
          }
        >
          <div
            className={clsx('cursor-pointer text-truncate', className)}
            onMouseEnter={() => setShowPopover((prev) => ({...prev, tooltip: true}))}
            onMouseLeave={() => setShowPopover((prev) => ({...prev, tooltip: false}))}
          >
            {message}
          </div>
        </OverlayTrigger>
      )
    },
    [showPopover]
  )

  const onSelectStatus = useCallback(
    (option, row: any) => {
      if (option.value === row.original.release_status) return

      setShowModal((prev) => ({...prev, massUpdate: true}))
      setDataModal((prev) => ({
        ...prev,
        massUpdate: {
          selectAll: false,
          selectedList: {
            selected: [row],
            total: tableDataAll,
          },
          typeMassUpdate: option.typeMassUpdate,
          releaseStatus: option.releaseStatus,
          rowId: row.original.id,
        },
      }))
    },
    [tableDataAll]
  )

  const columns = useMemo(() => {
    const _columns = [
      {
        id: 'created_at',
        Header: intl.formatMessage({id: 'DATE'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-dark',
        Cell: ({row}: {row: any}) => {
          return (
            row.original?.created_at &&
            convertUserTimeZone(row.original?.created_at, currentUser, FORMAT_DATE.DATE)
          )
        },
      },
      {
        id: 'code',
        Header: 'BAE ID',
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap text-dark',
        Cell: ({row}: {row: any}) => row.original.bae_id,
      },
      {
        id: 'content',
        Header: intl.formatMessage({id: 'CONTENT'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-dark',
        Cell: ({row}: {row: any}) =>
          !isEmpty(row.original?.order_items) && (
            <div>
              {row.original?.order_items?.map((item: any, idx) => {
                const content = find(A1_CONSTANTS.OPTIONS_CONTENT_TYPE, {value: item?.a1_content})
                return (
                  content && (
                    <div className='d-flex' key={idx}>
                      <span className='mw-200px text-truncate'>
                        {intl.formatMessage({id: content?.label})}
                      </span>
                    </div>
                  )
                )
              })}
            </div>
          ),
      },
      {
        id: 'description',
        Header: intl.formatMessage({id: 'DESCRIPTION'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-dark',
        Cell: ({row}: {row: any}) =>
          !isEmpty(row.original?.order_items) && (
            <div>
              {row.original?.order_items?.map((item: any, idx) => {
                return (
                  <div className='d-flex' key={idx}>
                    <span className='mw-200px text-truncate'>{item?.a1_description}</span>
                  </div>
                )
              })}
            </div>
          ),
      },
      {
        id: 'ship_to',
        Header: intl.formatMessage({id: 'SHIP_TO'}),
        headerClassName: 'min-w-200px',
        cellClassName: 'text-wrap text-dark',
        Cell: ({row}: {row: any}) => {
          const {to_street1, to_street2, to_city, to_state, to_zip} = row.original.order_ext
          const addressTo = compact([
            to_street1,
            to_street2,
            to_city,
            to_state && to_zip ? `${to_state} ${to_zip}` : to_state || to_zip,
          ]).join(', ')
          return addressTo
        },
      },
      {
        id: 'hts_code',
        Header: intl.formatMessage({id: 'HTS_CODE'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-dark',
        Cell: ({row}: {row: any}) =>
          !isEmpty(row.original?.order_items) && (
            <div>
              {row.original?.order_items?.map((item: any, idx) => {
                return (
                  <div className='d-flex' key={idx}>
                    <span className='mw-200px text-truncate'>{item?.a1_hs_tariff_number}</span>
                  </div>
                )
              })}
            </div>
          ),
      },
      {
        id: 'container_id',
        Header: intl.formatMessage({id: 'CONTAINER_ID'}),
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-dark',
        Cell: ({row}: {row: any}) => {
          return row?.original?.container_id || null
        },
      },
      {
        id: 'country',
        Header: intl.formatMessage({id: 'COUNTRY_OF_ORIGIN'}),
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap text-dark',
        Cell: ({row}: {row: any}) =>
          !isEmpty(row.original?.order_items) && (
            <div>
              {row.original?.order_items?.map((item: any, idx) => {
                const country = find(OPTION_COUNTRIES, {value: item?.a1_country})
                return (
                  country && (
                    <div className='d-flex' key={idx}>
                      <span className='mw-200px text-truncate'>{country?.label}</span>
                    </div>
                  )
                )
              })}
            </div>
          ),
      },
      {
        id: 'quantity',
        Header: intl.formatMessage({id: 'QUANTITY'}),
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-wrap text-dark',
        Cell: ({row}: {row: any}) =>
          !isEmpty(row.original?.order_items) && (
            <div className='d-flex flex-column align-items-center'>
              {row.original?.order_items?.map((item: any, idx) => {
                return (
                  <div className='d-flex' key={idx}>
                    <span className='mw-200px text-truncate'>{item?.a1_quantity}</span>
                  </div>
                )
              })}
            </div>
          ),
      },
      {
        id: 'value',
        Header: intl.formatMessage({id: 'VALUES_$'}),
        headerClassName: 'min-w-150px text-center',
        cellClassName: 'text-wrap text-dark text-center',
        Cell: ({row}: {row: any}) =>
          !isEmpty(row.original?.order_items) && (
            <div className='d-flex flex-column align-items-center'>
              {row.original?.order_items?.map((item: any, idx) => {
                return (
                  <div className='d-flex' key={idx}>
                    <span className='mw-200px text-truncate'>{item?.a1_value}</span>
                  </div>
                )
              })}
            </div>
          ),
      },
      {
        id: 'tracking_number',
        Header: intl.formatMessage({id: 'TRACKING_NUMBER'}),
        headerClassName: 'min-w-150px',
        cellClassName: 'text-wrap text-dark',
        Cell: ({row}: {row: any}) => {
          return row?.original?.tracking_number || null
        },
      },
      {
        id: 'hawb',
        Header: 'HAWB',
        headerClassName: 'min-w-100px',
        cellClassName: 'text-wrap text-dark',
        Cell: ({row}: {row: any}) => {
          return row?.original?.hawb || null
        },
      },
      {
        id: 'type',
        Header: intl.formatMessage({id: tabName === 'hold' ? 'HOLD_TYPE' : 'REJECTION_TYPE'}),
        headerClassName: 'min-w-175px',
        cellClassName: 'text-wrap text-dark',
        Cell: ({row}: {row: any}) => {
          const foundType =
            find(statesGlobal.settingsConfig?.[row?.original?.first_release_status], {
              value: row?.original?.[`${tabName}_type`],
            }) || null

          return foundType?.label || null
        },
      },
      {
        id: 'reason',
        Header: intl.formatMessage({id: 'REASON'}),
        headerClassName: 'min-w-100px text-center',
        cellClassName: 'text-wrap text-dark text-center',
        Cell: ({row}: {row: any}) => {
          return toolTipModal({
            message: (
              <div
                className='cursor-pointer'
                onClick={() => {
                  setDataModal((prev) => ({
                    ...prev,
                    reasonData: {
                      type: row?.original?.[`${tabName}_type`],
                      reason: row?.original?.[`${tabName}_reason`],
                      first_release_status: row?.original?.first_release_status,
                    },
                  }))
                  setShowModal((prev) => ({...prev, reason: true}))
                }}
              >
                <div className='d-flex align-items-center text-danger'>
                  <KTSVG path='/media/gori/common/invalid.svg' className='svg-icon-3 text-danger' />
                  <span className='ms-2 text-decoration-underline text-uppercase'>
                    {intl.formatMessage({id: 'REASON'})}
                  </span>
                </div>
              </div>
            ),
            messageTooltip: row?.original?.[`${tabName}_reason`],
          })
        },
      },
      isOrigin && {
        id: 'status',
        Header: intl.formatMessage({id: 'RELEASE_STATUS'}),
        headerClassName: clsx('fixed-action text-center text-nowrap px-2 min-w-1px right-0'),
        cellClassName: clsx('text-dark fixed-action text-center text-nowrap px-2 right-0'),
        Cell: ({row}: {row: any}) => {
          return <StatusViewOnly status={row.original.release_status} />
        },
      },
      isDestination && {
        id: 'status',
        Header: intl.formatMessage({id: 'RELEASE_STATUS'}),
        headerClassName: clsx('fixed-action text-center text-nowrap px-2 min-w-1px right-0'),
        cellClassName: clsx('text-dark fixed-action text-center text-nowrap px-2 right-0'),
        Cell: ({row}: {row: any}) => {
          return (
            <UpdateStatus
              row={row}
              onChangeCallback={(option) => onSelectStatus(option, row)}
              isUpdating={updatingStatus[row.original.id]}
            />
          )
        },
      },
    ]

    if (isDestination) {
      const canCheck: any = {
        id: TABLE_KEY_COLUMN.CAN_CHECK,
        Header: intl.formatMessage({id: 'ID'}),
        accessor: 'canCheck',
      }
      _columns.splice(1, 0, canCheck)
    }
    return _columns.filter((col) => col)
  }, [
    intl,
    tabName,
    isOrigin,
    isDestination,
    currentUser,
    statesGlobal.settingsConfig,
    toolTipModal,
    updatingStatus,
    onSelectStatus,
  ])

  const handleMassUpdate = (option) => {
    setShowModal((prev) => ({...prev, massUpdate: true}))
    setDataModal((prev) => ({
      ...prev,
      massUpdate: {
        selectAll: selectedList.length < pagination?.total ? selectAll : false,
        selectedList: {
          selected: selectedList,
          total: tableDataAll,
        },
        typeMassUpdate: option.value,
      },
    }))
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      {showModal.massUpdate && (
        <MassUpdateModal
          show={showModal.massUpdate}
          handleClose={() => {
            const targetRowId = dataModal?.massUpdate?.rowId

            if (targetRowId) {
              const targetUpdatingState = updatingStatus[targetRowId]

              setUpdatingStatus((prev) => ({
                ...prev,
                [targetRowId]: !targetUpdatingState,
              }))
            }
            setShowModal((prev) => ({...prev, massUpdate: false}))
            setShowModal((prev) => ({...prev, massUpdate: false}))
          }}
          data={dataModal.massUpdate}
          reloadTable={() => {
            setTable()
          }}
        />
      )}
      {showModal.reason && (
        <ReasonModal
          show={showModal.reason}
          handleClose={() => setShowModal((prev) => ({...prev, reason: false}))}
          data={dataModal.reasonData}
          isDisabled={true}
        />
      )}
      <Modal
        id='gori_modal_upload_file_modal'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1250px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'HOLD_REJECTED_ORDERS'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <TableWrapper>
              {loading.first ? (
                <TableSkeleton countRow={8} />
              ) : (
                <>
                  <InfiniteScroll
                    dataLength={tableData}
                    next={fetchMore}
                    hasMore={hasLoadMore}
                    height='50vh'
                    loader={
                      loading.getOrder && (
                        <div className='d-flex justify-content-center'>
                          <span className='spinner-border' />
                        </div>
                      )
                    }
                    endMessage={
                      <p className='d-flex justify-content-center'>
                        {intl.formatMessage({id: 'NO_MORE_ITEMS'})}
                      </p>
                    }
                  >
                    <div className='card'>
                      {selectedList.length > 0 && (
                        <div className='d-flex align-items-center flex-wrap gap-3 mb-2'>
                          <div className='fw-bolder me-5'>
                            <span className='me-2'>
                              {selectAll ? pagination.total : selectedList.length}
                            </span>
                            {intl.formatMessage({id: 'SELECTED'})}
                          </div>
                          {selectedMaster && selectedList.length < pagination?.total && (
                            <div
                              onClick={() => setSelectAll(!selectAll)}
                              className='fw-bolder me-5 link-blue text-decoration-underline cursor-pointer'
                            >
                              {selectAll
                                ? intl.formatMessage({id: 'CLEAR_SELECTION'})
                                : intl.formatMessage({id: 'SELECT_ALL'}) + ` ${pagination.total}`}
                            </div>
                          )}
                          {showMassUpdate && (
                            <SelectFormik
                              className='w-200px fw-bold fs-5'
                              value={{label: intl.formatMessage({id: 'MASS_UPDATE'}), value: ''}}
                              options={Object.values(A1_CONSTANTS.MASS_UPDATE)}
                              onChange={handleMassUpdate}
                              hasUseIntl={true}
                              emptyDefault={false}
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <TableTabs
                      style={{minHeight: '360px'}}
                      dataTabs={statistic}
                      keyCheckActive='release_status'
                      disableTabAll={true}
                      children={
                        <TableWrapper className='rounded-top-left-0-dt border-top border-light'>
                          <TableBody className='px-0'>
                            <Table
                              columns={columns}
                              data={tableData}
                              tbodyClass='text-gray-600 fw-bold'
                              useCheckBox
                              usePagination={false}
                              callBackSetData={(selected) => {
                                setSelectedMaster(selected?.length === tableData?.length)
                                setSelectedList(selected)
                              }}
                            />
                          </TableBody>
                        </TableWrapper>
                      }
                    />
                  </InfiniteScroll>
                </>
              )}
            </TableWrapper>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {HoldOrderModal}
