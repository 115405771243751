import {ChangeEvent, useCallback, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {A1_CONSTANTS} from '../../app/modules/a1'
import {fileHelper} from '../helpers'

type FileFormat = {
  label: string
  value: string
}

type UploadFile = {
  fileInput: File[]
  onSubmit: (file: File[]) => void
  supportedFormat?: FileFormat[] | null
  maxRow?: number
  maxUpload?: number
  size?: {
    label: string
    value: number
  } | null
  multiple?: boolean
}

const UseUploadFile = ({
  fileInput,
  onSubmit,
  supportedFormat = Object.values(A1_CONSTANTS.UPLOAD_CONFIG.SUPPORTED_FORMATS),
  maxRow = 0,
  maxUpload = 0,
  size = {label: '8MB', value: A1_CONSTANTS.UPLOAD_CONFIG.SIZE},
  multiple = true,
}: UploadFile) => {
  const intl = useIntl()

  const fileType = useMemo(() => {
    return {
      label: supportedFormat?.map((item) => item?.label).join(', '),
      value: new Set(supportedFormat?.map((item) => item?.value)),
    }
  }, [supportedFormat])

  const setFile = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files?.length) return
      const files = multiple ? Array.from(e.target.files) : [e.target.files[0]]
      e.target.value = ''

      if (maxUpload && files.length + fileInput.length > maxUpload) {
        return toast.warning(
          intl.formatMessage({id: 'UPLOAD_UP_TO_INPUT_FILES'}, {input: maxUpload})
        )
      }

      const validFiles: File[] = []
      for (const file of files) {
        if (!fileType.value.has(file.type)) {
          toast.error(
            intl.formatMessage(
              {id: 'FILE_INPUT_HAS_INVALID_EXTENSION_ONLY_EXTENSION_ARE_ALLOWED'},
              {fileName: file.name, extension: fileType.label}
            )
          )
          continue
        }
        if (maxRow) {
          const {row} = await fileHelper.checkRowHaveData(file)
          if (row > maxRow) {
            toast.error(
              intl.formatMessage({id: 'CANNOT_UPLOAD_MORE_THAN_INPUT_ITEMS'}, {input: maxRow})
            )
            continue
          }
        }
        if (size && file.size > size.value) {
          toast.warning(
            intl.formatMessage(
              {id: 'FILE_INPUT_ERROR_UPLOADING_PLEASE_UPLOAD_FILES_SMALLER_THAN_INPUT'},
              {fileName: file.name, size: size.label}
            )
          )
          continue
        }
        validFiles.push(file)
      }

      if (validFiles.length > 0) {
        onSubmit(multiple ? [...validFiles, ...fileInput] : validFiles)
      }
    },
    [fileInput, fileType.label, fileType.value, intl, maxRow, maxUpload, multiple, onSubmit, size]
  )

  const handleDelete = useCallback(
    (index: number) => {
      const updatedFiles = fileInput.filter((_, i) => i !== index)
      onSubmit(updatedFiles)
    },
    [fileInput, onSubmit]
  )
  return {setFile, handleDelete}
}

export {UseUploadFile}
