import clsx from 'clsx'
import {getIn, useFormik} from 'formik'
import {cloneDeep} from 'lodash'
import React, {useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {KTSVG} from '../../../../../../../gori/components'
import {useCancelToken, UseUploadFile, UseYupValidate} from '../../../../../../../gori/hooks'
import {useA1BatchesProvider} from '../../../../../../../gori/providers'
import {ValidationErrorModal} from '../../../../../../../gori/widgets'
import {A1_CONSTANTS} from '../../../../core/constants'
import {A1BatchService} from '../../../../core/requests'

type Props = {
  reloadTicketDetail: () => void
}

const CommentTicket: React.FC<Props> = ({reloadTicketDetail}) => {
  const intl = useIntl()
  const {isCancel, newCancelToken} = useCancelToken()
  const {ticketForum} = useA1BatchesProvider()
  const {stringYup} = UseYupValidate()
  const fileInputRef = React.useRef<HTMLInputElement | null>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [isFocused, setIsFocused] = useState(false)
  const [validationErrors, setValidationErrors] = useState<any>()
  const [loading, setLoading] = useState<{send: boolean}>({
    send: false,
  })

  const [fileInput, setFileInput] = useState<File[]>([])
  const {setFile, handleDelete} = UseUploadFile({
    fileInput,
    maxUpload: 10,
    onSubmit: (files) => setFileInput(files),
  })

  const formik = useFormik({
    initialValues: {feedback: '', attachments: []},
    validationSchema: Yup.object({
      feedback: stringYup(5000, 'FEEDBACK', false),
      attachments: Yup.array(),
    }),
    enableReinitialize: true,
    onSubmit: () => {
      handleSendFeedback()
    },
  })
  const formikErrors = getIn(formik.errors, 'feedback')
  const formikTouched = getIn(formik.touched, 'feedback')
  const hasFeedbackError = formikTouched && formikErrors

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      if (formik.values.feedback.trim() && !loading?.send) {
        formik.handleSubmit()
      }
    }
  }

  const handleChangeTextEditor = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    formik.setFieldValue('feedback', event.target.value)
    autoResize(event.target)
  }

  const autoResize = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = 'auto'
    if (textarea.scrollHeight > textarea.clientHeight) {
      textarea.style.height = `${Math.min(textarea.scrollHeight, 150)}px`
    }
  }

  const handleSendFeedback = async () => {
    if (!ticketForum?.detailId) return

    try {
      setLoading((prev) => ({...prev, send: true}))
      const payload = new FormData()
      payload.append('content', formik.values.feedback.trim())
      const attachments = cloneDeep(fileInput)
      if (attachments.length > 0) {
        attachments.map((file) => {
          payload.append('attachments[]', file)
          return file
        })
      }
      const res: any = await A1BatchService.postComments(ticketForum.detailId, payload, {
        cancelToken: newCancelToken(),
      })
      if (res) {
        toast.success(res.message)
        setFileInput([])
        formik.resetForm()
        autoResize(textareaRef.current as HTMLTextAreaElement)
        reloadTicketDetail()
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setLoading((prev) => ({...prev, send: false}))
    }
  }

  const sendBtn = useMemo(() => {
    const isDisabled =
      hasFeedbackError || loading?.send || (!formik.values.feedback.trim() && fileInput.length <= 0)
    const isActive =
      !hasFeedbackError && !loading?.send && (formik.values.feedback.trim() || fileInput.length > 0)
    return {isDisabled, isActive}
  }, [fileInput.length, formik.values.feedback, hasFeedbackError, loading?.send])

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <div className='mt-4 border-top border-gray-300 p-6'>
        {fileInput.length > 0 && (
          <div
            className={clsx(
              'scroll-x d-flex border border-bottom-0 rounded-top h-100px transition',
              {
                'border-gray-400': isFocused && !hasFeedbackError,
                'border-gray-300': !isFocused && !hasFeedbackError,
                'border-danger': hasFeedbackError,
              }
            )}
          >
            {fileInput.map((file, idx) => (
              <div
                key={idx}
                className={clsx('ms-4 mt-4 mb-4 position-relative', {
                  'me-4': idx === fileInput.length - 1,
                })}
              >
                {file?.type.includes('image') ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    className='h-75px min-w-75px w-75px object-fit-cover border rounded-2'
                  />
                ) : (
                  <div className='h-100 d-flex align-items-center min-w-200px mw-200px border border-gray-300 rounded-2 p-3 bg-light'>
                    <span className='me-4'>
                      <KTSVG path='/media/gori/a1/file.svg' className='svg-icon-1 text-dark' />
                    </span>
                    <span className='text-truncate-2'>{file.name}</span>
                  </div>
                )}
                <div
                  className='position-absolute top-0 end-0 bg-white px-1 rounded-2 cursor-pointer'
                  onClick={() => handleDelete(idx)}
                >
                  <KTSVG
                    path='/media/gori/common/delete.svg'
                    className='m-0 text-dark text-hover-danger'
                    svgClassName='mh-10px'
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        <div className='position-relative'>
          <textarea
            autoComplete='off'
            rows={1}
            ref={textareaRef}
            placeholder={intl.formatMessage({id: 'TYPE_YOUR_FEEDBACK_HERE'})}
            {...formik.getFieldProps('feedback')}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              formik.setFieldTouched('feedback', true)
              setIsFocused(false)
            }}
            onKeyDown={handleKeyDown}
            onChange={handleChangeTextEditor}
            className={clsx(
              'form-control py-4 ps-4 pe-30 resize-none scroll-y scrollbar-width-none min-h-50px transition-none',
              {
                'is-invalid': hasFeedbackError,
                'border-top-0 rounded-top-0': fileInput.length > 0,
              }
            )}
          />
          <div className='d-flex position-absolute top-50 end-0 translate-middle-y'>
            <div
              className={clsx('cursor-pointer mx-4', {
                'cursor-no-drop': hasFeedbackError,
                'text-hover-primary': !hasFeedbackError,
              })}
              onClick={() => fileInputRef.current?.click()}
            >
              <KTSVG path={'/media/gori/a1/attachments.svg'} className='svg-icon-1 text-dark' />
              <input
                type='file'
                ref={fileInputRef}
                onChange={setFile}
                className='d-none'
                multiple
                accept={Object.values(A1_CONSTANTS.UPLOAD_CONFIG.SUPPORTED_FORMATS)
                  .map((item) => item.value)
                  .join(',')}
              />
            </div>
            <div
              className={clsx('cursor-pointer mx-4', {
                'cursor-no-drop': sendBtn.isDisabled,
                'text-hover-primary': sendBtn.isActive,
              })}
              onClick={() => sendBtn.isActive && formik.handleSubmit()}
            >
              <KTSVG path={'/media/gori/a1/send.svg'} className='svg-icon-1 text-dark' />
            </div>
          </div>
        </div>
        {hasFeedbackError && (
          <div className='fv-plugins-message-container mt-2'>
            <div className='fv-help-block text-danger'>
              <span role='alert'>{formikErrors}</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {CommentTicket}
