import Underline from '@tiptap/extension-underline'
import {EditorContent, useEditor} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import clsx from 'clsx'
import {getIn} from 'formik'
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../../gori/components'
import {UseUploadFile} from '../../../../../../../gori/hooks'
import {A1_CONSTANTS} from '../../../../core/constants'

type Props = {
  editor: any
  onSubmit: (files: File[]) => void
}

const MenuBar: React.FC<Props> = ({editor, onSubmit}) => {
  const fileInputRef = React.useRef<HTMLInputElement | null>(null)
  const [fileInput, setFileInput] = useState<File[]>([])
  const {setFile, handleDelete} = UseUploadFile({
    fileInput,
    maxUpload: 10,
    onSubmit: (files) => {
      onSubmit(files)
      setFileInput(files)
    },
  })

  if (!editor) return null

  const options = [
    {
      key: 'undo',
      icon: <i className='fs-2 text-dark bi bi-arrow-counterclockwise' />,
      onClick: () => editor.chain().focus().undo().run(),
      disable: !editor.can().chain().focus().undo().run(),
    },
    {
      key: 'redo',
      icon: <i className='fs-2 text-dark bi bi-arrow-clockwise' />,
      onClick: () => editor.chain().focus().redo().run(),
      disable: !editor.can().chain().focus().redo().run(),
    },
    {key: 'interrupt'},
    {
      key: 'heading',
      icon: <i className='fs-2 text-dark bi-type-h1' />,
      onClick: () => editor.chain().focus().toggleHeading({level: 1}).run(),
      isActive: editor.isActive('heading', {level: 1}),
    },
    {
      key: 'heading',
      icon: <i className='fs-2 text-dark bi-type-h2' />,
      onClick: () => editor.chain().focus().toggleHeading({level: 2}).run(),
      isActive: editor.isActive('heading', {level: 2}),
    },
    {
      key: 'heading',
      icon: <i className='fs-2 text-dark bi-type-h3' />,
      onClick: () => editor.chain().focus().toggleHeading({level: 3}).run(),
      isActive: editor.isActive('heading', {level: 3}),
    },
    {key: 'interrupt'},
    {
      key: 'bold',
      icon: <i className='fs-2 text-dark bi bi-type-bold' />,
      onClick: () => editor.chain().focus().toggleBold().run(),
    },
    {
      key: 'italic',
      icon: <i className='fs-2 text-dark bi bi-type-italic' />,
      onClick: () => editor.chain().focus().toggleItalic().run(),
    },
    {
      key: 'underline',
      icon: <i className='fs-2 text-dark bi bi-type-underline' />,
      onClick: () => editor.chain().focus().toggleUnderline().run(),
    },
    {key: 'interrupt'},

    {
      key: 'strike',
      icon: <i className='fs-2 text-dark bi bi-type-strikethrough' />,
      onClick: () => editor.chain().focus().toggleStrike().run(),
    },
    {
      key: 'orderedList',
      icon: <i className='fs-2 text-dark bi bi-list-ol' />,
      onClick: () => editor.chain().focus().toggleOrderedList().run(),
    },
    {
      key: 'bulletList',
      icon: <i className='fs-2 text-dark bi bi-list-ul' />,
      onClick: () => editor.chain().focus().toggleBulletList().run(),
    },
    {
      key: 'upload',
      icon: (
        <>
          <input
            type='file'
            ref={fileInputRef}
            onChange={setFile}
            className='d-none'
            multiple
            accept={Object.values(A1_CONSTANTS.UPLOAD_CONFIG.SUPPORTED_FORMATS)
              ?.map((item) => item.value)
              .join(',')}
          />
          <i className='fs-2 text-dark bi bi-upload cursor-pointer' />
        </>
      ),
      onClick: () => fileInputRef.current?.click(),
    },
  ]

  return (
    <div>
      {fileInput.length > 0 && (
        <div className={clsx('scroll-x d-flex h-100px transition')}>
          {fileInput.map((file, idx) => (
            <div
              key={idx}
              className={clsx('ms-4 mt-4 mb-4 position-relative', {
                'me-4': idx === fileInput.length - 1,
              })}
            >
              {file?.type.includes('image') ? (
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  className='h-75px min-w-75px w-75px object-fit-cover border rounded-2'
                />
              ) : (
                <div className='h-100 d-flex align-items-center min-w-200px mw-200px border border-gray-300 rounded-2 p-3 bg-light'>
                  <span className='me-4'>
                    <KTSVG path='/media/gori/a1/file.svg' className='svg-icon-1 text-dark' />
                  </span>
                  <span className='text-truncate-2'>{file.name}</span>
                </div>
              )}
              <div
                className='position-absolute top-0 end-0 bg-white px-1 rounded-2 cursor-pointer'
                onClick={() => handleDelete(idx)}
              >
                <KTSVG
                  path='/media/gori/common/delete.svg'
                  className='m-0 text-dark text-hover-danger'
                  svgClassName='mh-10px'
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className='d-flex flex-wrap gap-2'>
        {options.map((option: any, idx) =>
          option.key === 'interrupt' ? (
            <div key={idx} className='border border-gray-300 border-1 my-2' />
          ) : (
            <div
              key={idx}
              className={clsx('p-2 bg-hover-light rounded-2 text-dark d-flex align-items-center', {
                'bg-secondary': option.isActive ?? editor.isActive(option?.key),
                'cursor-pointer': !option?.disable,
                'opacity-50 cursor-no-drop': option?.disable,
              })}
              onClick={() => !option?.disable && option?.onClick()}
            >
              {option.icon}
            </div>
          )
        )}
      </div>
    </div>
  )
}

type TextEditorProps = {
  formik: any
  name: string
  fileName: string
}

const TextEditor: React.FC<TextEditorProps> = ({formik, name, fileName}) => {
  const intl = useIntl()
  const fieldProps = formik.getFieldProps(name)
  const formikErrors = getIn(formik.errors, name)
  const formikTouched = getIn(formik.touched, name)
  const hasError = formikTouched && formikErrors

  function stripHTMLUsingDOM(htmlString) {
    return new DOMParser().parseFromString(htmlString, 'text/html').body.textContent?.trim() ?? ''
  }

  const editor = useEditor({
    extensions: [StarterKit, Underline],
    editorProps: {
      attributes: {
        class: 'outline-none h-150px scroll-y mt-2',
      },
    },
    content: fieldProps.value,
    onUpdate: ({editor}) => {
      formik.setFieldValue(name, stripHTMLUsingDOM(editor.getHTML()) ? editor.getHTML() : '')
    },
    onBlur: () => {
      formik.setFieldTouched(name, true)
    },
  })

  const handleSubmitFile = (files) => {
    formik.setFieldValue(fileName, files)
  }

  if (!editor) {
    return null
  }

  return (
    <div>
      <label className='form-label required'>
        {intl.formatMessage({id: 'TICKET_DESCRIPTION'})}
      </label>
      <div
        className={clsx('form-control', {
          'is-invalid': hasError,
          'is-valid': !hasError && fieldProps.value,
        })}
      >
        <MenuBar editor={editor} onSubmit={handleSubmitFile} />
        <hr className='mt-1' />
        <EditorContent editor={editor} />
      </div>
      {hasError && (
        <div className='fv-plugins-message-container mt-2'>
          <div className='fv-help-block text-danger'>
            <span role='alert'>{formikErrors}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export {TextEditor}
