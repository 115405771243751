import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../components'
import {formatBytes} from '../../helpers'
import {UseUploadFile} from '../../hooks'
import {Button} from '../form/Button'

type FileFormat = {
  label: string
  value: string
}

type Props = {
  className?: any
  supportedFormat: FileFormat[]
  maxRow?: number
  maxUpload?: number
  size: {
    label: string
    value: number
  }
  onSubmit: (data: {files: File[]}) => void
  reloadForm?: boolean
  multiple?: boolean
}

const UploadFile: React.FC<Props> = ({
  className,
  supportedFormat = [],
  maxRow = 0,
  maxUpload = 0,
  size,
  onSubmit,
  reloadForm = false,
  multiple = false,
}) => {
  const intl = useIntl()
  const [fileInput, setFileInput] = useState<File[]>([])
  const {setFile, handleDelete} = UseUploadFile({
    fileInput,
    onSubmit: (file) => {
      setFileInput(file)
      onSubmit({files: file})
    },
    supportedFormat,
    maxRow,
    maxUpload,
    size,
    multiple,
  })

  useEffect(() => {
    if (reloadForm) {
      setFileInput([])
    }
  }, [reloadForm])

  return (
    <>
      <div className={clsx('dropzone', className)}>
        <div className='d-flex justify-content-center my-4'>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className='fs-4 fw-bold text-dark mb-1'>
              {intl.formatMessage({id: 'DRAG_AND_DROP_HERE_OR'})}
              <span className='ms-2 text-primary'>{intl.formatMessage({id: 'BROWSE_FILES'})}</span>
            </div>
            <Button
              className='btn btn-secondary mt-4'
              label={intl.formatMessage({id: 'UPLOAD_FILE'})}
            />
          </div>
          <input
            type='file'
            id='file-upload'
            name='file-img'
            onChange={setFile}
            className='dropzone__upload mt-n10 cursor-pointer'
            multiple={multiple}
            accept={supportedFormat.map((item) => item.value).join(',')}
            disabled={maxUpload > 0 && fileInput.length >= maxUpload}
          />
        </div>
      </div>
      {fileInput.map((item: any, index) => (
        <div key={index} className='d-flex align-items-center mt-2 col-6'>
          <div className='flex-fill d-flex justify-content-between bg-light-primary p-3 rounded-2 fw-bold w-75'>
            <span className='text-truncate'>{item?.name}</span>
            <span className='text-nowrap'>{formatBytes(item?.size)}</span>
          </div>
          <div
            className='text-hover-danger cursor-pointer ms-4'
            onClick={() => handleDelete(index)}
          >
            <KTSVG path='/media/gori/common/empty.svg' small={false} />
          </div>
        </div>
      ))}
    </>
  )
}

export {UploadFile}
